.unique-img-container {
    margin-bottom: 15px; /* Adjust the spacing between images if needed */
}

.fixed-size-image {
  height: 100%; /* Set your desired height */
  width: 100%; /* Set your desired width */
  object-fit: cover; /* Ensure the image covers the specified dimensions without distortion */
}

.container-social {
    padding: 20px;
}

.down-content {
    text-align: center;
    margin-bottom: 20px;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .middleimg img {
    max-width: 100%;
  }

.unique-img {
    width: 100%;
    height: auto; /* Ensures the image maintains its aspect ratio */
}

.design-content-p {
  font-size: 18px;
  font-weight: 600;
  color: #666F74;
  text-align: left;
  align-items: justify;
  margin-top: 20px;
}

/* .image-container-design img {
    border: 2px solid black;
    border-radius: 10px;
} */

@media (max-width: 767px) {
    .unique-img-container {
        text-align: center; /* Center images on small screens */
    }
}


@media (max-width: 768px) {
    .middleimg {
      /* width: 100%; */
      margin-bottom: 20px;
    }
  }
  
  @media (min-width: 769px) {
    .middleimg {
      /* width: 50%; */
    }
  }