.fbmain p {
    color: rgb(218, 19, 19);
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 7%;
    font-size: 35px;
    font-weight: 600;
}

.fbmain {
    position: relative;
    margin-top: 170px;
}

.card:hover .card-body {
    background-color: rgb(41, 195, 41); /* Change background color of card body when hovering over the card */
    margin-top: 15px; /* Increase margin-top of card body when hovering over the card */
}


.second-main {
    background-image: url('C:\Users\prasa\OneDrive\Documents\react-phoenix\phoenix\Home\public\Imagefile\BG port 1.png'); 
    background-size: cover;
    background-position: center;
    padding: 20px; 
    width: 100%;
  }

.fb-card-title {
    font-size: 16px;
    /* margin-top: 15px; */
}

.card {
    border: none;
    /* width: 300px;
    height: 300px; */
    overflow: hidden;
    /* margin: 50px auto; */
    border-radius: 30px 30px 30px 30px;
}

.second-main2 {
    margin: 200px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.card-body {
    background-color: rgb(200, 17, 17);
    height: 60px;
    text-align: center;
    color: white;
}

.card img {
    /* width: 100%; */
    /* height: 100%; */
    object-fit: cover;
    /* object-position: top; */
    transition: all 1s ease;
    /* border-radius: 50px; */
}

.card:hover img {
    object-position: top center;
}

.card-img-top {
    transition: transform 0.3s ease; /* Add smooth transition for the zoom effect */
  }

.card:hover .card-img-top {
    transform: scale(1.1); /* Increase the size of the image on hover (zoom effect) */
}

h5 {
    color: white;
}

/* .second-main {
    margin: 170px;
    margin-top: 60px;
} */

@media (min-width: 320px) and (max-width: 567px) {

    .fbmain p {
        font-size: 17px;
    }

    .second-main2 {
        margin: 0px;
    }

    .fbmain {
        position: relative;
        margin-top: 0px;
    }
}

@media (min-width: 568px) and (max-width: 991px) {
    /* .second-main {
        margin: 25px !important;
        margin-top: 25px;
    } */

    .fbmain p {
        font-size: 26px;
    }

    .second-main2 {
        margin: 0px;
    }

    .fb-card-title {
        font-size: 16px;
    }

    .card img {
        object-fit: cover;
        transition: all 1s ease;
    }

    .main p {
        font-size: 16px;
    }

    .fbmain {
        position: relative;
        margin-top: 0px;
    }

}



@media (min-width: 991px) and (max-width: 1200px) {

    .second-main2 {
        margin: 80px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

