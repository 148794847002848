.explore-more {
    display: flex;
    justify-content: center;
}

.passionate {
    color: #CB0C1E;
    background-color: none;
    border-radius: 10px;
    font-size: 45px !important;
}

.explore-more button:hover {
    transform: translate(0, -15%) scale(1);
    transition: all 0.4s ease;
}

button {
    padding: 10px 30px;
    background: linear-gradient(to bottom, #4D0006, #B60014);
    color: white;
    font-size: 20px;
    font-weight: 600;
    /* margin-left: 43%; */
    margin-top: -35px;
    margin-bottom: 20px;
    transition: transform 500ms;
}

button a {
    color: white;
}

@media (min-width: 320px) and (max-width: 575px) {
    
    button {
        padding: 10px 10px;
        background: linear-gradient(to bottom, #4D0006, #B60014);
        color: white;
        font-size: 20px;
        font-weight: 600;
        margin-left: 20%;
        margin-top: -35px;
        margin-bottom: 52px;
        transition: transform 500ms;
        margin-left: 0px;
    }
}