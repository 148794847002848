.contactUs{
    position: relative;
    width: 100%;
    padding: 20px;
    background: #F5E4E5;
    border-radius: 20px;
}

.after-contactform-container {
    display: flex;
    align-items: center;
}

.after-contactform-messages {
    display: flex;
    justify-content: space-around;
}

.contact p {
    color: white;
    font-size: 30px;
}

.error-text {
    color: white;
    font-size: 15px;
}

.contact-in label {
    color: white;   
}

.contact-in input {
    width: 100%;
    border: none;
}

input {
    width: 80%;
    height: 50%;
}

.left-content-contact {
    padding-top: 20px;
    padding-left: 30px;
}

span {
    font-size: 24px;
}

.con-email a {
    color: rgb(84, 81, 81);
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 25px;
}

.con-ph a {
    color: rgb(89, 84, 84);
    font-size: 18px;
    font-weight: 600;
}

.submit {
    display: flex;
    justify-content: center;
}

.button {
    background-color: #02CA34;
    padding: 10px 50px;
    align-items: center;
    border-radius: 12px;
    color: white;
    font-weight: 600;
    border: 2px solid white;
    cursor: pointer;
}

.InputBox select {
    font-family: "Syne", sans-serif !important;
    font-size: 18px;
}
  
.title h1 {
    color: #AE0002;
    font-size: 34px;
    margin-top: 40px;
    margin-bottom: 0px;
}

.contactUs .title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}

.contactUs .title h1{
    color: black;
    font-weight: 500;
}

.form{
    grid-area: form;
}

.info{
    grid-area: info;
}

.map{
    grid-area: map;
}

.contactUsImage1 {
    padding-top: 95px;
    margin-top: 0px;
}

.contact{
    /* padding: 60px 170px; */
    /* margin: 20px; */
    background-color: #8D191A;
    /* background-color: #fff; */
    box-shadow: 0 9px 30px 10px rgba(0,0,0,0.17);
    border-radius: 0px;
    margin-bottom: 15px ;
}


.box{
    position: relative;
    /* display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 5fr 4fr;
    grid-template-areas:
    "form info"
    "form map" ;
    grid-gap: 20px;
    margin-top: 20px; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: #F5E4E5;
}

.title{
    text-align: center;
}

.contact h1 {
    color: #fff;
    font-weight: 500;
    font-size: 50px;
    margin-bottom: 30px;
    font-family: math;
    text-align: center;
    padding-bottom: 20px;   
}

.formBox{
    position: relative;
    width:100%;
    padding-left: 15%;
    padding-right: 15%;
}

.formBox .row50{
    display: flex;
    gap: 20px;
}

.InputBox{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px ;
    width: 50%;
}
.formBox .row100 .InputBox{
    width: 100%;
}
.InputBox span{
    color: #fff;
    margin-top: 10px ;
    margin-bottom: 5px;
    font-weight: 500;
}

.InputBox input{
    padding: 10px;
    font-size: 1.1rem;
    outline: none;
    border: 1px solid #333;    
}

.InputBox textarea{
    padding: 20px;
    font-size: 1.1rem;
    outline: none;
    border: 1px solid #333;   
    resize: none; 
    min-height: 220px;
    margin-bottom: 10px;
}

input, textarea, select{
    display: block;
    margin-bottom: 1rem;
    padding: 12px;
    /* border: none; */
    border-radius: 10px;
    /* box-shadow: 2px 1px 2px 0px var(--Secondary) ; */
    /* border-bottom: 1px solid var(--Secondary-light); */
}  

input:focus, textarea:focus{
    border-bottom: 2px solid var(--Secondary);
    box-shadow: 0px 0px 2px 2px var(--Secondary) ;
}

.InputBox input[type="submit"]{
    background:#02CA34;
    color: white;
    /* border: none; */
    border: 3px solid white;
    font-size: 1.1rem;
    max-width: 150px;
    font-weight: 500;
    cursor: pointer;
    /* padding: 14px 15px; */
}

.InputBox input[type="submit"]:hover{
    transform: translateY(5px);
    color: white;
    background-color: #2d6712;
}

.InputBox ::placeholder{
    color:#4f745a;
}

.container {
    background-color: #F7E6E6 !important;
    border-radius: 15px;
}

.con-address-section{
    /* padding: 60px 170px; */
    /* margin: 20px; */
    
    /* background-color: #fff; */
    /* box-shadow: 0 9px 30px 10px rgba(0, 0, 0, 0.17); */
    border-radius: 10px;
    /* margin-bottom: 15px; */
}
.con-address-image > img{
    width: 100%;
    height: 300px;
}
.con-infomation{
    /* background: #977071; */
    background: #F5E4E5;
    padding: 15px;
    /* margin: 20px; */
    /* margin-top: 0px; */
    /* padding: 60px 170px; */
    margin: 20px;
    /* background-color: #8D191A; */
    /* background-color: #fff; */
    /* box-shadow: 0 9px 30px 10px rgba(0, 0, 0, 0.17); */
    /* border-radius: 10px; */
    margin-bottom: 15px;
}

.con {
    background: #F5E4E5;
    width: 95%;
    border: 2px solid white;
    border-radius: 10px;
    padding: 0px;
}

.contact-second{
    padding: 50px 120px;
}

.con-locationaddress{
    font-size: 18px;
    font-weight: 500;
}

.con-locationaddress p {
    color: black;
}

.con-email{
    font-size: 18px;
    font-weight: 500;
}

.con-email a {
    color: black;
}

.con-ph {
    font-size: 18px;
    font-weight: 500;
}

.con-ph a {
    color: black;
}

.contact-in-message {
    width: 100%;
}

.contact-in-message textarea {
    width: 100%;
    height: 230px;
}

.contact-in-message label {
    color: white;
}


@media (min-width: 320px) and (max-width: 467px) {

    .formBox {
        padding-left: 0px;
        padding-right: 0px;
    }

    .con-address-image img {
        margin-left: 50px;
    }

    .contact {
        margin: 10px;
        padding: 15px;
    }

    .contact h1 {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .title h1 {
        font-size: 24px;
    }

    .sci {
        width: 85%;
    }

    .con-locationaddress p {
        font-size: 18px;
    }

    .con-email a {
        font-size: 16px;
    }

    .con-ph a {
        font-size: 18px;
    }

    input {
        width: 100%;
    }

    .contact-in-message  {
        width: 100%;
    }

    .con {
        background: #F5E4E5;
        width: 70%;
        border: 2px solid white;
        border-radius: 10px;
        padding: 0px;
    }
}


@media (min-width: 468px) and (max-width: 767px) {

    .formBox {
        padding-left: 0px;
        padding-right: 0px;
    }

    .con-address-image img {
        margin-left: 50px;
    }

    .con {
        width: 95%;
    }
}

@media (max-width: 991px) {
    .contactUsImage1 {
        padding-top: 0px;
        margin-top: 0px;
    }

    .contact-second {
        padding: 30px;
    }

    .con-address-image img {
        margin-left: 30px;
    }

    .con {
        width: 90%;
    }

    .contact-in-message input {
        width: 100%;
        height: 150px;
    }
}



