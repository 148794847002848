/* 
.sidebar-options {
    margin: 10px;
    width: 0;
    font-size: 24px !important;
    font-weight: 500;
}

.close-btn2 {
    background-color: rgb(238, 236, 236);
    font-size: 24px;
    margin: 25px;
}

.sidebar-mobile li {
    font-size: 20px;
    font-weight: 500;
}

.sidebar-mobile {
    position: fixed;
    top: 0;
    width: 300px;
    height: 100%;
    background-color: #fff;
    z-index: 1000;
}

.sidebar-mobile {
    position: fixed;
    top: 0;
    width: 300px;
    height: 100%;
    background-color: #fff;
    z-index: 1000;
    transition: width 2s;
    background-color: rgb(238, 236, 236);
}

.mobile-navbar-image {
    display: flex;
    justify-content: center;
    padding: 10px;
    height: 90px;
}

@media (min-width: 991px) {
    
    .sidebar-mobile {
        position: relative;
        left: -300px;
    }
}


@media (min-width: 320px) and (max-width: 767px) {

    .sidebar-mobile ul{
        margin: 15px;
    }

    .sidebar-mobile {
        width: 350px;
    }

}
 */
  
  .normal-nav.active a {
    color: #C40C0C;
  }
  
  
 .service-options .submenu {
    display: none; /* Hide submenu by default */
  }
  
  .service-options .submenu ul.service-toggle {
    display: none; /* Hide nested submenu by default */
  }
  
  .service-options .submenu ul.service-toggle.open {
    display: block; /* Show nested submenu when the open class is added */
  }
  

.service-toggle {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: white; /* Adjust as needed */
  z-index: 1000;
}

 .service-options .submenu {
    display: none;
    position: absolute;
    top: 50;
    left: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: white;
    z-index: 1000; 
  }
 
  
  .service-options {
    position: relative; /* Ensure submenu is positioned relative to service-options */
  }
  
  .service-options .submenu .service-toggle {
    display: none;
    /* position: relative; */
    /* top: 50%; */
    left: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: white; /* Adjust as needed */
    z-index: 1000; /* Ensure it is above other content */
  }
  
 
  


.list-row .sidebar-options a:hover {
    color: #C40C0C;
}

.list-row .sidebar-option {
    display: none;
    position: absolute;
    top: 0;
    left: 100%; /* Position the submenu to the right of the parent */
    background-color: white; /* Optional: Set background color */
    border: 1px solid #ccc; /* Optional: Add border */
    z-index: 1000; /* Optional: Ensure submenu appears on top of other content */
  }

.service-toggle a {
    font-size: 16px;
    /* padding: 0px; */
}

.chatwithus-mobile:hover {
    background-color: rgb(94, 243, 94);
}

 .sidebar-options {
    margin: 10px;
    width: 0;
    font-size: 24px !important;
    font-weight: 500;
}

.menu_list .normal-navbar:hover {
    background-color: rgb(94, 243, 94);
}
  
.menu_list .normal-nav a:hover {
    color: #C40C0C;
}

.close-btn2 {
    background-color: rgb(238, 236, 236);
    font-size: 24px;
    margin: 25px;
}

.close-btn-mbl {
    background-color: #EEECEC;
    border-style: none;
    font-size: 24px;
    padding: 20px;
    /* margin: 15px; */
    color: black;
}

.sidebar-mobile li {
    font-size: 20px;
    font-weight: 500;
}

.sidebar-mobile {
    position: fixed;
    top: 0;
    width: 300px;
    height: 100%;
    background-color: #fff;
    z-index: 1000;
}

.sidebar-mobile {
    position: fixed;
    top: 0;
    width: 300px;
    height: 100%;
    background-color: #fff;
    z-index: 1000;
    transition: width 2s;
    background-color: rgb(238, 236, 236);
}

.mobile-navbar-image {
    display: flex;
    justify-content: center;
    padding: 10px;
    height: 70px;
}

@media (min-width: 991px) {
    
    .sidebar-mobile {
        position: relative;
        left: -300px;
    }
}
 

@media (min-width: 320px) and (max-width: 767px) {

    .sidebar-mobile ul{
        margin: 15px;
    }

    .sidebar-mobile {
        width: 260px;
    }

}

