/* Roboto font */
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.address-footer {
    color: black;
    font-size: 16px;
    font-weight: 600;
    margin-left: 5px;
}

.startbtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    max-width: 12%;
    transition: all 0.3s ease;
    max-width: 300px; /* Max width for larger screens */
    box-sizing: border-box; /* Include padding and border in width */
  }

.footer-content-p {
    color: black;
    width: 100%;
    /* font-family: Georgia, 'Times New Roman', Times, serif !important; */
}

  .startbtn:hover .hover-design {
    transform: translate(-50%, -50%) scale(1);
  }

.text-center img {
    cursor: pointer;
}

/* .text-center img:hover {
    transform: translate(-03%, -03%) scale(1);
    transition: all 0.4s ease;
} */

.slider-container {
    width: 80%; /* Adjust as needed */
    margin: 0 auto; /* Center the slider */
    background-image: url(imagesold/4thsec_laptop.png); /* Replace 'background.jpg' with the path to your background image */
    background-size: cover;
    background-position: center;
  }

.img-fluid-home {
    max-width: 100%;
    height: 450px;
}

.startbtn-quote {
    color: white;
}

.img-fluid {
    max-width: 80%;
    height: auto;
    margin-top: 50px;
}

/* Add this CSS to your stylesheets or inline style */

/* Remove background color from left and right arrows */
.carousel-control-prev,
.carousel-control-next {
    background-color: transparent !important;
}


.carousel-indicator {
    display: none !important;
}

.para {
    color: white;
}

  .fa-solid.fa-arrow-right {
    width: 10%;
  }

  .counter-item .counter-number {
    font-size: 80px;
  }

  .counter-item .counter-label {
    font-size: 26px;
    font-weight: 700;
    color: black;
    margin-left: 10px;
    margin-bottom: 15px;
  }
  
  .container-about {
    max-width: 100%;
    padding: 0 20px;
    margin: 0 auto;
  }

  .startbtn i {
    width: 0px;
    padding-left: 15px;
    margin-top: 10px;
  }
  
  .about-section {
    display: flex;
    align-items: center; /* Vertically center the items */
  }
  
  .about-image-container {
    flex: 0 0 auto; /* Don't grow, don't shrink, initial width */
    margin: 6%;
  }
  
  .about-content {
    flex: 1;
  }
  
  .about-content h1 {
    text-align: left;
    font-size: 22px;
    font-weight: 600;
    color: #737373;
  }

  .about-content h3 {
    text-align: left;
    color: #AE0002;
    font-weight: 600;
    font-size: 38px;
    max-width: 75%;
    margin-top: 10px;
  }

  .about-content p {
    text-align: left;
    color: #010101;
    font-size: 22px;
    font-weight: 700;
    max-width: 60%;
    margin-top: 10px;
  }
  
  .start-btn-about {
    padding: 10px 10px;
    background-color: #0056b3;
    color: #fff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    font-size: 24px;
    margin-top: 10px;
  }
  
  .start-btn-about:hover {
    background-color: #0056b3;
  }
  
  /* Media Query for Responsiveness */
  @media screen and (max-width: 768px) {
    .about-section {
      flex-direction: column;
    }
  
    .about-image-container {
      margin-bottom: 20px;
    }
  
    .about-image {
      height: auto;
      width: 100%;
    }

    .img-fluid-home {
        max-width: 80%;
        height: 300px;
        padding: 15px;
    }
  }
  
  
  
  /* Additional styling for the images within the slider */
  .slider-container img {
    width: 100%; /* Ensure images fill their container */
    height: auto;
  }
  


* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* font-family: sans-serif; */
    font-family: 'Arial', sans-serif !important;
}

/* Add these styles to your CSS file */

/* Add these styles to your CSS file */

.carousel-indicator {
    display: none;
}

@media (min-width: 320px) and (max-width: 991px) {
    .main-content-home {
        position: relative;
        top: 20px !important;
    }

    .main-contents {
        position: relative;
        top: 20px !important;
    }
}

.submenu {
    position: relative; /* Set the parent container to relative positioning */
  }
  
  .submenu .service-toggle {
    display: none;
    position: absolute;
    top: 0;
    left: 100%; /* Position the submenu to the right of the parent */
    background-color: white; /* Optional: Set background color */
    border: 1px solid #ccc; /* Optional: Add border */
    z-index: 1000; /* Optional: Ensure submenu appears on top of other content */
  }

  .submenu .digital-link:hover .service-toggle {
    display: block;
  }

  .submenu .digital-marketing:hover {
    /* color: rgb(39, 222, 213); */
    color: #A80012;
  }

  .submenu:hover li {
    /* color: mediumspringgreen; */
    color: #A80012;
  }
  
  /* .submenu:hover .service-toggle {
    display: block;
  } */

.main-content-home {
    position: relative;
    top: 96px;
}

.main-contents {
    position: relative;
    top: 96px !important;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    background-color: white;
}

  
.service-toggle {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    color: black;
}


.menu-container {
    position: relative;
  }
  
  .close-btn {
    padding: 0px 5px;
    background-color: white;
    font-size: 25px;
    border: none;
    font-weight: 600;
  }
  

.chatwithus {
    padding: 10px;
    background-color: #33e35f;
    border-radius: 15px;
}

.chatwithus-mobile {
    padding: 10px;
    background-color: #33e35f;
    border-radius: 15px;
    width: 80%;
    color: #444444;
    cursor: pointer;
}

.chatwithus-mobile .chat {
    color: #444444;
    margin-left: 5px;
}

.youtube {
    height: 30px;
    width: 30px;
    margin-right: 5px;
}

.mobile-menu-icon {
    border: none;
    font-size: 22px;
    margin: 0px;
    background: white;
    color: black;
}

  li:hover .submenu {
    display: block;
  }

  li:hover .submenu3 {
    display: block;
  }
  

.mobileNav_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .images {
    order: 2; /* To place the toggle icon on the right */
    cursor: pointer;
  }
  
  .mobileNav_menu_container {
    position: relative;
  }

  .mobileNav_menu_container {
    width: 100%;
  }
  
  .mobileNav_menu_list {
    list-style-type: none;
    padding: 0;
  }
  
  .mobileNav_menu_list li {
    padding: 10px;
    /* Add your other styles as needed */
  }
  
  /* Rest of your CSS */
  .Heading-client {
    font-size: 32px;
    text-align: center;
    align-items: center;
    color: brown;
    margin-bottom: 20px;
    font-weight: 700;
  }


/* Rest of your CSS */


.submenu {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .menu_list li:hover .submenu {
    display: block;
  }
  
  .submenu li:hover .submenu2 {
    display: block;
  }
  
  li :hover {
    color: #444444;
  }

li a {
    color: #444444;
    font-weight: 600;
    font-size: 16px;
    padding: 5px;
}

.footer-service-ul li {
    font-size: 13px;
}
  
/* Navbar */
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px 10px 30px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.05);
  }

  /* Mobile Navbar */
.menu_icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 2;
}

.mobileNav_menu_container {
  position: relative;
  z-index: 1;
}

.mobileNav_menu_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  margin: auto;
  background: brown;
  border-radius: 4px;
  color: white;
  transition: ease all 0.4s;
}

.mobileNav_container {
  display: none;
  padding: 10px;
}

.mobileNav_closed {
  top: -400px;
}

.mobileNav_open {
  top: 0px;
}

.menu_list {
    display: flex;
    cursor: pointer;
}

ul .menu_list {
    padding: 15px;
}

.carousel-indicators {
    display: none !important;
  }
  


/* Media Queries */
@media only screen and (max-width: 991px) {
  /* Navbar Media Queries */
  .navbar {
    display: none;
  }
  /* Mobile Navbar Media Queries */
  .mobileNav_container {
    display: flex;
    /* flex-direction: column; */
  }

  .mobileNav_container img {
    height: 55px;
    width: 135px;
  }

  .mobileNav_container .images {
    height: 30px;
    width: 30px;
  }
}

@media (min-width: 520px) and (max-width: 650px) {
    .mobileNav_container a {
        position: relative;
        left: 0;
      }
    
}

@media (min-width: 420px) and (max-width: 520px) {
    .mobileNav_container a {
        position: relative;
        left: 0;
      }
}

@media (min-width: 320px) and (max-width: 420px) {
    .mobileNav_container a {
        position: relative;
        left: 0;
      }
    
}


/* navbar */

@media (max-width: 991px) {
    .menu {
        display: none;
    }
}

@media (min-width: 992px) {
    .toggle-icon {
        display: none;
    }
}


.menu {
    list-style: none;
}

@media (min-width: 320px) and (max-width: 991px) {
    .menu {
        list-style: none;
        display: none;
    }
}

.menu > li {
    position: relative; /* Add this line to make the submenu position relative to the parent li */
}

.menu > li, .menu > a {
    display: inline-block;
}

.menu a {
    text-decoration: none;
    color: #000;
    display: block;
    padding: 10px 1.5rem;
    transition: background-color .22s ease, color .22s ease;
}

.menu a:hover {
    background-color: #f34949;
    color: black;
    border-radius: 5px;
}

.submenu {
    position: absolute;
    display: none;
    padding: 0px;
    background-color: none;
    border-radius: 5px;
}

.submenu .digital-link {
    /* background-color: lightgray; */
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    font-size: 5px;
}


.submenu .digital-link:hover {
    /* color: rgb(39, 222, 213); */
    color: #C40C0C;
}

.digital-link {
    padding: 15px;
    font-size: 14px;
}

.submenu .web-link {
    /* background-color: lightgray; */
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
}


.submenu .web-link:hover {
    /* color: rgb(39, 222, 213); */
    color: #C40C0C;
}

.web-link {
    padding: 15px;
}

.submenu .graphic-link {
    /* background-color: lightgray; */
    padding: 20px 20px 20px 20px;
    border-radius: 5px;
}

.submenu .graphic-link:hover {
    /* color: rgb(39, 222, 213); */
    color: #C40C0C;
}

.graphic-link {
    padding: 15px;
}


.submenu2 {
    position: absolute;
    left: 100%;
    width: 300px;
    top: 5px;
    display: none;
    background-color: #fff;
    border-radius: 5px;
}

.submenu2 li a {
    color: black;
}

.menu li:hover > .submenu {
    display: block;
}

.submenu li:hover > .submenu2 {
    display: block;
}

.foot {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.btn {
    background-color: rgb(8, 84, 249) !important;
}

.carousel-container1 {
    background-image: url(imagesold/4thsec_laptop.png);
    background-size: 50% 100%; /* Cover the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat;
    margin-left: 60px;
} 

@media (min-width: 320px) and (max-width: 450px) {
    .carousel-container1 {
        background-image: url(imagesold/4thsec_laptop.png);
        background-size: 100% 100%; /* Cover the entire container */
        background-position: center; /* Center the background image */
        background-repeat: no-repeat;
    }
}

.owl-nav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    display: none;
}

.owl-carousel .item {
    height: 200px;
    width: 200px;
    border: 2px solid black;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 465px) {
    .owl-carousel .item {
        position: relative;
        left: 60px;
    }
}


.service-menu .submenu {
    display: none;
    position: absolute;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #FA7070; /* Adjust as needed */
    color: white !important;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Adjust as needed */
    width: 150px;
  }
  
  .service-menu:hover .submenu {
    display:  block;
  }
  
  .service-menu {
    position: relative;
  }
  
  .submenu li {
    padding: 10px;
    color: black;
    font-weight: 600;
  }
  
  .submenu li a {
    color: #000; /* Adjust as needed */
    text-decoration: none;
  }
  
  .submenu li:hover {
    background-color: #f0f0f0; /* Adjust as needed */
  }

.slider-wrapper .slide-button {
    position: absolute;
    /* height: 50px;
    width: 50px; */
    color: #fff;
    background-color: black;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    font-size: 2.2rem;
    transform: translateY(-50%);
}

.slider-wrapper {
    position: relative;
}

.slider-wrapper .slider-button:hover {
    background: #444;
}
  
.slider-wrapper .slider-button #prev-slide {
    left: -20px;
}

.slider-wrapper .slider-button #next-slide {
    right: -20px;
}

.container {
    max-width: 1200px;
    width: 95%;
}

.slider-wrapper .image-list::-webkit-scrollbar {
    display: none;
}

.container .slider-scrollbar {
    height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
}

.slider-scrollbar .scrollbar-track {
    height: 2px;
    width: 100%;
    background-color: #ccc;
    position: relative;
    border-radius: 4px;
}

.slider-scrollbar .scrollbar-thumb {
    position: absolute;
    height: 100%;
    width: 50%;
    background-color: #ccc;
}

.slider-scrollbar .scrollbar-thumb:active {
    cursor: grabbing;
    height: 8px;
    top: -2px;
}

.slider-scrollbar .scrollbar-thumb::after {
    content:"";
    position: absolute;
    left: 0;
    right: 0;
    top: -10px;
    bottom: -10px;
}

.slider-wrapper .image-list {
    display: grid;
    gap: 18px;
    font-size: 0;
    margin-bottom: 30px;
    overflow-x: auto;
    scrollbar-width: none;
    grid-template-columns: repeat(10, 1fr);
}

.slider-wrapper .image-list .image-item {
    width: 325px;
    height: 400px;
    object-fit: cover;
}
 

  .carousel-item-red {
    background-color: red;
  }
  
  /* Add similar classes and styles for other carousel items */
  

  .carousel-container {
    width: 100%;
  }
  
  .carousel-container .carousel {
    width: 100%;
  }
  
  .carousel-container .carousel .carousel-inner {
    width: 100%;
    height: 100%;
  }
  
  .carousel-container .carousel .carousel-inner .container {
    display: flex;
    justify-content: center;
    position: relative;
    left: 22%;
  }
  
  @media (max-width: 768px) {
    .carousel-container .carousel {
      max-width: 100%;
    }

  }
  

.gallery-wrap {
    display: flex;
    align-items: center;
    overflow-x: auto; /* This will allow horizontal scrolling if needed */
    overflow-y: hidden; /* This will hide vertical scrollbar if any */
    padding: 10px; /* Adjust padding as needed */
  }
  
  .gallery {
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping of images */
  }
  
  .gallery img {
    margin-right: 10px; /* Adjust spacing between images as needed */
    max-width: 100%; /* Ensure images don't exceed container width */
    height: auto; /* Maintain aspect ratio */
  }
  

/* variable decleartion */
:root{
    --Primary : #BF0014;
    --primary-light : #bf3947;
   --Secondary : #3B0002;
   --Secondary-light : #612627;
}

.second-paragraph {
    font-family: Lato;
    font-size: 30px;
  }


  
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* Adjust height as needed */
    background-color: white; /* Optional background color */
    z-index: 100; /* Ensure header stays on top */
  }
  
body{
    width: 100%;
    min-height: 100vh;
    background:white ;
    position: absolute;
}

a{
    text-decoration: none;
}

li{
   list-style-type: none !important; 
}
/* img{
    width: 100%;
    height: 100%;
} */

/* ::selection{
    color: white;
    background-color: var(--Secondary);
} */

.fa-bars{
    color: #BF0014;
    font-size: 2rem;
}
.fa-times{
    color: black;
    font-weight: 900;
    font-size: 2rem;
}

.svg-inline--fa {
    display: none;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}


.scroll-to-top{ 
    cursor: pointer;  
    position: fixed;
    z-index: 2147483626;
    bottom: 33px;
    right: 20px;
    width: 35px;
    height: 35px;
    -webkit-border-radius: 100px;
    border-radius: 10px;
    border: 2px solid #A80012;
    color: white;
    opacity: 1;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(imagesold/scroll.svg);
    box-shadow: 0 10px 10px rgb(17 17 17/30%);
    background-color: white;
}


/* .whatsapp-icon{   
    position: fixed;
    z-index: 2147483626;
    bottom: 80px;
    left: 10px;
    width: 30px;
    height: 30px;
    -webkit-border-radius: 100px;
    border-radius: 100px;
    color: #ffffff;
    opacity: 1;
    background-color: #bd2525fe;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(imagesold/whatsapp.svg);
    box-shadow: 0 10px 10px rgb(17 17 17/30%);
} */

.whatsapp-icon {
    cursor: pointer;
    position: fixed;
    z-index: 1000;
    bottom: 25px;
    left: 25px;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    color: #ffffff;
    opacity: 1;
    background-color: #25d366;
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center center;
    background-repeat: no-repeat;
    /* background-image: url(imagesold/whatsapp1.svg); */
    /* box-shadow: 0 10px 10px rgb(17 17 17/30%); */
}


/* common style */
.btn{
    color: white;
    display: inline-block;
    padding: 10px 18px;
    transition: 300ms;
    background-color: #3B0002;
}

.btn:hover{
    transform: translateY(5px);
    color: white;
    background-color: var(--Secondary-light);
        
}

.flex-design{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.contactUsImage{
    width: 100%;
    height: 90vh;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px;
    background-image: url(imagesold/contactus1.jpg);

}

.contactUsImage1{
    padding-top: 100px;
    margin-top: 40px;
}

.header-content{
    padding: 0 3rem;
    height: 20vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation:  header 2s;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}
@keyframes header{
   0%{
     transform:  translatey(-100%);
   }
}

.gallery {
    width: 100%;
    display: flex;
    overflow-x: scroll;
}

::-webkit-scrollbar {
    width: 0;  /* For Chrome, Safari, and Opera */
    background-color: transparent;
}

.gallery div {
    width: 100%;
    display: flex;
    grid-template-columns: auto auto auto;
    grid-gap: 100px;
    padding: 10px;
    flex: none;
}

.gallery div img {
    width: 100%;
    filter: grayscale(100%);
    transition: transform
}

.gallery .arrow img {
    width: auto;
}

.gallery-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10% auto;
}

#nextbtn, #backbtn {
    cursor: pointer;
    width: 50px;
    margin: 40px;
}

.gallery div img:hover {
    filter: grayscale(0);
    cursor: pointer;
    transform: scale(1.1);  
}

.galler::-webkit-scrollbar {
    display: none;
}

/* .header-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 3rem;
    height: 85px; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: header 2s ease-in-out;
    background-color: rgb(213, 163, 163); 
    z-index: 1000;
} */


.logo{
    min-width: 20px;
    height: 85px;
}


.nav-links{
    width: 65%;
    font-size: 20px;
    text-align: center;
    margin-bottom: -4px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif ; 
}


.nav-links a{
    color: black !important;
}


.nav-links li::after{
    content: '';
    display: block;
    background-color: var(--Primary);
    height: 3px;
    transform: scaleX(0);
    transition: transform 300ms;
}

.nav-links li:not(:last-child)::after {
    content: '';
    display: block;
    background-color: var(--Primary);
    height: 3px;
    transform: scaleX(0);
    transition: transform 300ms;
}

/* Override or remove the background color for the last li element */
.nav-links li:last-child::after {
    background-color: transparent; /* Set to transparent or any other color */
}



.nav-links li:hover::after{
    transform: scaleX(1);
}

.burger{
    display: none;
    cursor: pointer;
}

/* .fa-whatsapp {
    background-color: #02CA34;
    color: white;
    font-size: 20px;
    padding: 5px;
    margin: 2px 3px 2px 5px;
    border-radius:25px ;   
} */

.fa-whatsapp {
    background-color: #02CA34;
    color: white;
    font-size: 40px;
    padding: 7px;
    margin: 0px 0px 0px 0px;
    border-radius: 30px;  
}

.prev-arrow {
    background-color: grey;
    width: 30px;
}

.next-arrow {
    background-color: grey;
    width: 30px;
}


/* .carousel-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
} */

.next-arrow {
    color: grey !important;
}

.watsapp{
    background-color: rgb(2,202,52);
    color: white;
    border-radius: 100px;
    cursor: pointer;
    padding: 10px;
}

@media (min-width: 320px) and (max-width: 991px) {
    .watsapp {
        background-color: #db4554;
        color: white;
        border-radius: 100px;
        cursor: pointer;
        padding: 10px;
    }

    .main-contents {
        position: relative;
        top: 0px !important;
    }

    .nav-show {
        width: 100%;
        left: 0px;
        background-color: #FA7070;
        color: black;

    }
}

@media (min-width: 991px) and (max-width: 1300px) {
    .watsapp {
        background-color: #db4554;
        color: white;
        border-radius: 100px;
        cursor: pointer;
        padding: 10px;
    }

    .nav-show {
        width: 100%;
        left: 0px;
        background-color: #FA7070;
        color: black;

    }
}


.whatsapp a{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 31px;
    color: white;
    padding: 9px;
}



.responsive-image {
    max-width: 90%; /* Ensure image doesn't exceed container width */
    height: auto; /* Maintain aspect ratio */
}

.text-content {
    padding: 20px; /* Add padding to text content */
}

.text-contents {
    display: flex;
    align-items: flex-start; /* Align content to the top */
}

.header-bg{
    min-height: 70vh;
    background-image:  url(imagesold/header_background.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden; 
}
.homecontent{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: 3%;
}
.text-center{
    width:50%;
    margin-top: 100px; 
    padding: 15px;
    color: white;
}


.text-center p{
  font-size: 18px;
  color: white;
  margin-bottom: -1%;
  margin-left: 22.5%;
  letter-spacing: 1px;
  text-align: left;
  animation: headercontent 2s ease-in-out;
}
@keyframes headercontent{
    0%{
        opacity: 0;        
        transform: translatex(-50%);
    }
}

.text-center h2{
    margin: 1.1rem 0;
    color: white;
    text-align: left;
    font-size: 45px;
    margin-bottom: 3%;
    margin-left: 22%;
    animation: headercontent 2s ease-in-out;
}
@keyframes headercontent{
    0%{
        opacity: 0;        
        transform: translatex(-50%);
    }
}


.startbtn{
    padding: 10px 30px;
    background-color: #fff;
    color: #6e0d17;
    font-size: 20px;
    font-weight: 600;
    margin-right: 33%;
    border: white;
    transition: transform 500ms ;
}

.text-center a {
    background-color: white;
    color: black;
}

.startbtn:hover,
.startbtn:focus-visible{
    background:white;
    color: black;
    transform: translatey(-0.75rem);
}

.fa-solid{
    width: 12%;
    padding-left: 10px;
}

.homesection-image-all{
    width: 50%;
    cursor: pointer;
}

.homesection-image{
    width: 67%;
    margin-top: 55px;
    margin-left: 60px;
    margin-bottom: 19px;
    transition: transform 0.5s ease-in-out;
    animation: headerimage 2s ease-in-out;
}
@keyframes headerimage {
    0%{
        transform: translatex(100%);
    }
    
}
.homesection-image:hover{
    transform: scale(1.2);
}

/*********** home page style end ************/



/*********** About page style start ************/
.about-page-bg{
    background-color: #2d6712;
    min-height: 100vh;
    background-position: contain;
    background-repeat: no-repeat;
}


.about-page-section{
    display: flex;
    justify-content: space-between;

}
/***************************************  ABOUT US  *********************************************************/


.aboutsection{
    min-height: 87vh;
    background-image:   url(imagesold/2nd_secBG.jpg);
    /* One method */
    /* background-size:contain;        
    background-repeat: no-repeat; */
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
}

.aboutcontent{
    width: 100%;
    display: flex;

}
.about-text-center{
    width:50%;
    margin-top: 13%;
    color: white;
    overflow: hidden;
    animation: aboutcontent 2s ease-in-out;
}
@keyframes aboutcontent{
    0%{
        opacity: 0;        
        transform: translatey(100%);
    }
}

.about-text-center p{
  margin-left: 16%;
  font-size: 20px;
  font-weight: 500;
  color: #01192E;
}

.about-text-center h1{
    margin-left: 15%;
    color: gray;
    font-size: 50px;
}

.about-text-center h3{
    margin-left: 16%;
    color:#B90112;
    font-size: 32px;
    font-weight: 650;
}

.startbtn-about{
    padding: 7px 25px;
    background: linear-gradient(to bottom, #4D0006, #B60014 );
    color:white;
    font-size: 20px;
    font-weight: 600;
    margin-left: 16%;
    transition: transform 500ms;
    margin-bottom: 20px;
}


.startbtn-about:hover,
.startbtn-about:focus-visible{
    background:white;
    color: #4D0006;
    transform: translatey(-0.75rem);
}

.fa-solid{
    width: 12%;
    padding-left: 10px;
}

.aboutsection-image-all{
    width: 50%;
    margin-top: 330px;
}

.aboutsection-image{
    width: 38rem;
    margin-left: 16%;
    transition: transform 0.5s ease-in-out;
    animation: headercontent 2s ease-in-out;
}
@keyframes headercontent{
    0%{
        opacity: 0;        
        transform: translatex(-50%);
    }
}

.aboutsection-image:hover{
    transform: scale(1.2);
}

/****************************************  SERVICES SECTION ********************************************/

.servicesection {
    background-image: url(imagesold/3rd_secBG.png);
    /* One method */
    /* background-size:contain;        
    background-repeat: no-repeat; */
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    height: 80vh;
}

.servicecontent{
    width: 100%;
    height: 21vh;
    display: flex;
    justify-content: space-evenly;
    animation: aboutcontent 2s ease-in-out;
}
@keyframes aboutcontent{
    0%{
        overflow: hidden;
        opacity: 0;        
        transform: translatey(100%);
    }
}


.servicesection-image1{
    margin-top: 50px;
    height: fit-content;
    /* margin-bottom: 40px ; */
    display: flex;
}

.servicesection-image2{
    margin-top: 45px;
    height: fit-content;
    /* margin-bottom: 40px; */
    display: flex;
}

.servicesection-image3{
    margin-top: 50px;
    height: fit-content;
    /* margin-bottom: 40px; */
    display: flex;
}

.servicesection-image-tech{
    width: 90px;    
}

.servicesection-image{
    width: 60px; 
}

.servicesection-text1{
    font-size: 30px;
    margin: 20px 20px 20px;
    color: white;
}

.servicesection-text2{
    font-size: 30px;
    margin: 20px 12px 10px;
    color: white;
}
.servicesection-text3{
    font-size: 30px;
    margin: 20px 20px 20px;
    color: white;
}



/****************************************  PORTFOLIO SECTION ********************************************/

.portfolio-bg{
    background-image: url(imagesold/4thBG.jpg);
    /* One method */
    /* background-size:contain;        
    background-repeat: no-repeat; */
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%; 
}
/* .portfolio-content {
    padding: 10px;
} */
.portfolio-content p {
    text-align: center;
    font-size: 50px;
    font-weight:600 ;
    color: #CB0C1E;
    margin-bottom: 0px;
    animation: slat 1s ease-in-out;
    animation: portfolio-p  1s ease-in-out;
}
@keyframes portfolio-p {
    0%{
        opacity: 0;        
        transform: translatey(100%);
    }
}

.portfolio-content h3 {
    text-align: center;
    font-size: 33px;
    color: #02182D;
    font-weight: 500;
    animation: portfolio-h3 1s ease-in-out;
    margin-bottom: 30px;
}
@keyframes portfolio-h3{
    0%{
        opacity: 0;        
        transform: translatey(100%);
    }
}




.portfolio-bulb {
    margin-top: -13%;
    margin-left: 90px;
    width: 9rem;
    animation: bulb 1s ease-in-out;
}
@keyframes bulb{
    0%{
        opacity: 0;        
        transform: translatex(-50%);
    }
}


.portfolio-lap {
    width: 53rem;
    margin-left: 16%;
    margin-top: -20px;
}


.portfolio-slat{
    width: 9rem;
    margin-top: 25%;
    animation: slat 1s ease-in-out;
}
@keyframes slat{
    0%{
        opacity: 0;        
        transform: translatex(50%);
    }
}



.startbtn-protfolio{

    padding: 10px 30px;
    background: linear-gradient(to bottom, #4D0006, #B60014 );
    color:white;
    font-size: 20px;
    font-weight: 600;
    margin-left: 40%;
    margin-top: -35px;
    margin-bottom: 52px;
    transition: transform 500ms;
}


.startbtn-protfolio:hover,
.startbtn-protfolio:focus-visible{
    background:white;
    color: #4D0006;
    transform: translatey(-0.75rem);
}


/************************************************** OUR SERVICES ***********************************************/

.ourservice-header{
    min-height: 100vh;
    background-image: url(imagesold/5thsecBG.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
}
.ourservice-content{
    text-align: center;
    margin: auto;
    flex-direction: 30%;
    color: white;
}
.ourservice-content h3{
    font-size: 45px;
    font-weight: 600;
    animation: ourservice-h3 1s ease-in-out;
}
@keyframes ourservice-h3{
    0%{
        opacity: 0;        
        transform: translatey(100%);
    }
}


.para{
    font-size: 35px;
    padding-top: 10px;
    font-weight: 700;
    animation: para 1s ease-in-out;
}
@keyframes para{
    0%{
        opacity: 0;        
        transform: translatey(100%);
    }
}


.ourservice-alert {
    margin-right: 80%;
    width: 11rem;
    margin-top: 3%;
    margin-bottom: -190px;
    animation: alert 1s ease-in-out;
}
@keyframes alert{
    0%{
        opacity: 0;        
        transform: translatex(-50%);
    }
}

.ourservice-seo {
    margin-left: 80%;
    width: 9rem;
    margin-top: -16%;
    margin-bottom: -120px;
    animation: seo 1s ease-in-out;
}
@keyframes seo{
    0%{
        opacity: 0;        
        transform: translatex(50%);
    }
}


.ourservice-box {
    display: grid;
    grid-template-columns: auto auto ;
    padding: 40px;
    margin-top: -15px;
}


.ourservices-box1{
    cursor: pointer;
    background-color: white;
    color: #950C19;
    border: 1px solid rgba(0, 0, 0, 0.8);
    margin: 15px;
    padding: 28px;
    text-align: left;
    display: flex;
    border-radius: 5px;
    transition: transform 500ms ;
}
.ourservices-box1:hover,
.ourservices-box1:focus-visible{
        transform: translatey(-0.90rem);
}


.service-content p{
    /* font-size: 15px;
    color: black; */

    font-size: 15px;
    color: black;
    /* margin-top: -15px; */
}

.service-content{
    margin-left: 20px;
}

.service-png{
    /* margin-left: 30px;
    height: fit-content;
    margin-top: 35px; */
    height: 50px;
    width: 50px;
    margin-left: 15px;
    margin-top: 30px;
}



/****************************************  TESTIMONIAL SECTION ********************************************/

.testimon-header{
    background-image: url(imagesold/6thsection_background.jpg);
    /* One method */
    /* background-size:contain;        
    background-repeat: no-repeat; */
    /* background-size: 100%; */
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
    /* min-height: 78vh; */
}

.testimon-content{
    text-align: center;
    margin: auto;
    flex-direction: 30%;
}

.testimon-content h3 {
    font-size: 50px;
    font-weight: 600;
    padding-top: 30px;
    color: #C80114;

    animation: portfolio-h3 1s ease-in-out;
}
@keyframes portfolio-h3{
    0%{
        opacity: 0;        
        transform: translatey(100%);
    }
}


.testimon-content p {
    font-size: 33px;
    margin-top: 10px;
    font-weight: 500;
    color: #01172C;

    animation: portfolio-h3 1s ease-in-out;
}
@keyframes portfolio-h3{
    0%{
        opacity: 0;        
        transform: translatey(100%);
    }
}

.testimon-msgpng {
    margin-left: 70%;
    width: 11rem;
    margin-top: -20%;
    margin-bottom: -50px;
}

/* .testimon-review{
    background-color: #fFF;
    color: black;
    border-radius: 20px;
    margin: 26px 300px;
    padding: 22px 30px;
    display: flex;
    overflow: hidden;
} */

.testimon-review {
    background-color: #fFF;
    color: black;
    border-radius: 20px;
    margin: 0px 160px;
    padding: 5px 5px;
    display: flex;
    overflow: hidden;
    transition: transform 0.5s ease-in-out;
    position: relative;
    right: 90%;
    border: 2px solid black;
    margin-bottom: 30px;
}

.carousel-control-prev-icon {
    background-color: gray; /* Change the background color */
  }
  
  /* Target the right arrow */
  .carousel-control-next-icon {
    background-color: gray; /* Change the background color */
  }

/* .testimon-review:hover{
    transform: scale(1.2);
} */


.reviwer-message{
    font-size: 18px;
}

.review1{
    text-align: left;
    margin: 90px 10px 50px 20px;
    
}
.review1 p{
    font-size: 17px;
    margin-top: 10px;
}

.review1 h5{
    font-size: 30px;
    font-weight: 650;
    margin-top: 18px;
    color: black;
}

.testimon-1stpng{
    height: fit-content;
    /* margin-top: 50px; */
    margin-top: 63px;
    margin-left: 33px;
}

.quatation{
    display: flex;
    justify-content: space-between;
    margin-top: -65px;
    /* margin-right: -8px; */

}
.quatationpng{
    margin-left: -40px;
    height: 30px;
}
.starpng{
   margin-right: 25px;
   height: 25px;
}

/**************************************** QUOTE SECTION ********************************************/


.quote-header{
    background-image: url(imagesold/7th_BG.jpg);
    /* background-size: cover; */
    background-size: 100%;
    background-repeat: no-repeat;
    max-height: 100vh;
}
.quote-content{
   text-align: center;
   width: 100%;
   /* display: flex;
   justify-content: end; */
}
.quote-content h2{
   padding-top: 100px;
   font-size: 40px;
   color: #fFF;
   font-weight: 650;
   line-height: 1.1;

   /* animation: portfolio-h3 1s ease-in-out;
}
@keyframes portfolio-h3{
    0%{
        opacity: 0;        
        transform: translatey(100%);
    } */
}


.quote-content span{
    font-size: 35px ;
    
}
.quote-png{
    display: flex;
    justify-content: flex-end;
}
.quote-png1{
    margin-top: -285px;
    width: 20rem;
    margin-right: 150px;

    animation: quotepng 1s ease-in-out;
}
@keyframes quotepng{
    0%{
        opacity: 0;        
        transform: translatex(50%);
    }
}


/* .quote-png1 {
    margin-top: -280px;
    width: 16rem;
    height: fit-content;
    margin-right: 30px;
} */


.startbtn-quote{
    padding: 14px 39px;
    background-color:white;    
    color:#5F0008;
    font-size: 25px;
    font-weight: 600;
    border: white;
    margin-top: 55px;
    transition: transform 500ms ;
}
 


.startbtn-quote:hover, .startbtn-quote:focus-visible{
    background:#5F0008;
    color:white;
    transform: translatey(-0.75rem);
}

/************************************************** CLIENT SECTION **********************************************/
.client-header{
    background-color: #ffffff;
}
.client-logos{
    /* background-color: white; */
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 10px;
    padding: 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.client-logos img{
    width: 10%;
    margin: 20px;
    transition: transform 0.3s ease-in-out;
}

.client-logos img:hover{
    transform: scale(1.2);

}


/********************************************* FOOTER SECTION ********************************************************/

.footer-header{
    background-image: url(imagesold/footerbg.png);
    background-size: 100%;
    background-repeat: no-repeat;
}
.footer-content{
   text-align: center;
   width: 100%;
   display: flex;
   /* justify-content: space-between; */
   padding: 15px;
}
.footer-logo{
    width: 28%;
    margin-top: 20px;
}
.footer-logo p{
    text-align: left;
    font-size: 18px;
    padding: 10px 20px;
    margin-left: 55px;
}
.footer-logo-png{
    width: 12rem;
    margin-bottom: 25px;
    padding: 10px 10px;
}

.footer-links{
    width: 60%;
    display: flex;
    text-align: left;
}

.footer-quicklink1{
    margin-top: 18px;
    padding: 22px;
    width: 37%;
}

.footer-quicklink2 {
    margin-top: 18px;
    padding: 22px;
    width: 50%;
}

.footer-quicklink3 {
    margin-top: 18px;
    padding: 22px;
    width: 70%;
}


.footer-service{
    margin-top: 15px;
    padding: 25px;

}


.footer-contact1{
    margin-top: 15px;
    padding: 25px;
}
.footer-ql-ul {
    font-size: 30px;
    font-weight: 500;
    color: #C40016;
}

/* .footer-ql-ul li{
    font-size: 20px;
} */
.footer-ql-ul li a{
    color: black;
}
.footer-ql-ul li a:hover{
    color: #7C010F;
}

/* .footer-service-ul p{
    font-size: 30px;
    font-weight: 500;
    color: #C40016;
} */
.footer-service-ul {
    font-size: 30px;
    font-weight: 500;
    color: #C40016;
}


.footer-service-ul li{
    font-size: 20px;
}
.footer-service-ul li a{
    color: #666F74;
    font-size: 13px;
}
.footer-service-ul li a:hover{
    color: #7C010F;
}

.con-head{
    font-size: 22px;
    color: #8A0716;
}

.con-head li{
    font-size: 20px;
}

/****************      other  Footer contact info         *******************/

.info-footer .InfoBoxfooter div{
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 5px;
}

.info-footer .InfoBoxfooter div span{
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
}
.info-footer .InfoBoxfooter div p{
    color: #666F74;
    font-size: 1.1rem;
    margin-left: 10px;
}
.info-footer .InfoBoxfooter div a{
    color: #666F74;
    text-decoration: none;
    font-size: 1.1rem;
    margin-left: 10px;
}

.copyright{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #7A000B;
}
.copyright p{
    font-size: 17px;
    color: white;
    margin-top: 20px;
}
.social{
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
    justify-content: center;
}

.social li .icons {
    color: #666F74;
}

.linkedin-icon:hover .icons:hover {
    color: #AE0002; /* Change this to the desired hover color */
  }
  

.social li{
    list-style: none;
    /* margin-right: 30px; */
}
.social li a{
    font-size: 1.3rem;
    color: white;
}
.social li a:hover{
    color: #d44141;
}

.content{
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto;
}

.mb{
    margin-bottom: 3rem;
}

.mt{
    margin-top: 5rem;
}

.wwa-heading{
    text-align: center;
}

.wwa-box{
    background-color: var(--primary-light);
    padding: 20px;
    margin-left: 10px;
    text-align: center;
    border-radius: 10px;
}

.wwa-box h3{
    margin-bottom: 6px;
    text-align: center;
}

.wwa-box:first-child{
    margin-left:0px
}
.wwa-box:hover{
    box-shadow:  2px 4px 15px 4px black
 }

/*****  new Contact1 form    *****/

/* body{
    background: linear-gradient(90deg, red 0%,  black 30%, #3B0002 30%, #612627 100%);
} */
.contactUs{
    position: relative;
    width: 100%;
    padding: 20px;
}
.contactUs .title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}
.contactUs .title h1{
    color: black;
    font-weight: 500;
}


.form{
    grid-area: form;
}

.info{
    grid-area: info;
}

.map{
    grid-area: map;
}

.contact{
    padding: 40px;
    background-color: #fff;
    /* box-shadow: 0 5px 35px rgba(0,0,0,0.15); */
    box-shadow: 0 9px 30px 10px rgba(0,0,0,0.17);
    border-radius: 10px;
    margin-bottom: 15px ;
}


.box{
    position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 5fr 4fr;
    grid-template-areas:
    "form info"
    "form map" ;
    grid-gap: 20px;
    margin-top: 20px;
}
.contact h3{
    color: #BF0014;
    font-weight: 500;
    font-size: 34px;
    margin-bottom: 30px;
    font-family: math;
}
.formBox{
    position: relative;
    width:100% ;
}
.formBox .row50{
    display: flex;
    gap: 20px;
}
.InputBox{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px ;
    width: 50%;
}
.formBox .row100 .InputBox{
    width: 100%;
}
.InputBox span{
    color: #612627;
    margin-top: 10px ;
    margin-bottom: 5px;
    font-weight: 500;
}
.InputBox input{
    padding: 10px;
    font-size: 1.1rem;
    outline: none;
    border: 1px solid #333;    
}
.InputBox textarea{
    padding: 20px;
    font-size: 1.1rem;
    outline: none;
    border: 1px solid #333;   
    resize: none; 
    min-height: 220px;
    margin-bottom: 10px;
}

input, textarea, select{
    display: block;
    margin-bottom: 1rem;
    padding: 26px;
    /* border: none; */
    border-radius: 10px;
    /* box-shadow: 2px 1px 2px 0px var(--Secondary) ; */
    /* border-bottom: 1px solid var(--Secondary-light);     */
}  

input:focus, textarea:focus{
    border-bottom: 2px solid var(--Secondary);
    box-shadow: 0px 0px 2px 2px var(--Secondary) ;
}

.InputBox input[type="submit"]{
    background:var(--Primary) ;
    color: white;
    border: none;
    font-size: 1.1rem;
    max-width: 120px;
    font-weight: 500;
    cursor: pointer;
    padding: 14px 15px;
}

.InputBox input[type="submit"]:hover{
    transform: translateY(5px);
    color: white;
    background-color: #2d6712;
    
}



.InputBox ::placeholder{
    color:#4f745a;
}


/*** information section ***/

.info{
    background: #977071;
    padding: 15px;
    border-radius: 15px;
    margin: 20px;
    margin-top: 0px;
}
.info h3{
    color: #fFF;
    margin-bottom: 50px;
    font-weight: 600;
}
.info .InfoBox div{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.info .InfoBox div span{
  min-width: 40px;
  height: 40px;
  color: #fff;
  background-color: #3B0002;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 1.3rem;
  border-radius: 50%;
}
.info .InfoBox div p{
    color: #fff;
    font-size: 20px;
    margin-left: 15px;
}
.info .InfoBox div a{
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    margin-left: 15px;
}
.sci{
    margin-top:40px ;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    letter-spacing: 10px;
}
.sci li{
    list-style: none;
    margin-right: 15px;
}
.sci li a{
    color: #fff;
    font-size: 1.5rem;
    color: black;
}
.sci li a:hover{
    color: #fff;
}
.map{
 margin: 20px;
 height: 300px;
}
.map iframe{
    height: 100%;
    width: 100%;
}

.form-label1 {
    font-size: 20px;
    font-weight: 600;
}

@media (min-width: 320px) and (max-width: 340px) {
    .logo {
        font-size: 10px;
    }
}

@media (min-width: 320px) and (max-width: 340px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 41px;
        bottom: 6px;
    }

    .carousel-inner {
        height: 300px;
    }
}

@media (min-width: 340px) and (max-width: 360px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 45px;
        bottom: 6px;
    }

    .carousel-inner {
        height: 300px;
    }
}

@media (min-width: 360px) and (max-width: 390px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 54px;
        bottom: 6px;
    }

    .carousel-inner {
        height: 300px;
    }
}

@media (min-width: 390px) and (max-width: 420px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 58px;
        bottom: 6px;
    }

    .carousel-inner {
        height: 300px;
    }

    .startbtn-about {
        padding: 7px 25px;
        background: linear-gradient(to bottom, #4D0006, #B60014 );
        color:white;
        font-size: 20px;
        font-weight: 600;
        margin-left: 16%;
        transition: transform 500ms;
        margin-bottom: 20px;
    }
}

@media (min-width: 420px) and (max-width: 440px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 62px;
        bottom: 6px;
    }

    .carousel-inner {
        height: 300px;
    }
}


@media (min-width: 440px) and (max-width: 464px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 66px;
        bottom: 6px;
    }

    .carousel-inner {
        height: 300px;
    }
}

@media (min-width: 464px) and (max-width: 477px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 67px;
        bottom: 6px;
    }

    .carousel-inner {
        height: 300px;
    }
}

@media (min-width: 464px) and (max-width: 991px) {
    
    .header-content {
        display: flex;
        justify-content: space-between;
        gap: 90%;
    }
}

@media (min-width: 320px) and (max-width: 464px) {
    
    .header-content {
        display: flex;
        justify-content: space-between;
        gap: 85%;
    }
}

@media (min-width: 476px) and (max-width: 565px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 71px;
        bottom: 6px;
    }

    .carousel-inner {
        height: 300px;
    }
}


@media (min-width: 565px) and (max-width: 767px) {
    .carousel-item .three-img {
        width: 45%;
        margin-top: 30px;
        position: relative;
        bottom: 9px;
        height: 254px;
        left: 25%;
    }
}


@media (min-width: 768px) and (max-width: 990px) {
    .carousel-item .three-img {
        width: 45%;
        margin-top: 30px;
        position: relative;
        bottom: 15px;
        height: 192px;
        left: 25%;
    }

    .carousel-inner {
        height: 300px;
    }
}

@media (min-width: 991px) and (max-width: 1198px) {
    .carousel-item .three-img {
        width: 45%;
        margin-top: 30px;
        position: relative;
        bottom: 9px;
        height: 254px;
        left: 25%;
    }
}

@media (min-width: 1199px) and (max-width: 1440px) {
    .carousel-item .three-img {
                width: 29%;
                margin-top: 30px;
                position: relative;
                height: 254px;
                bottom: 10px;  
                left: 34%;
    }
}

@media (min-width: 1441px) and (max-width: 1800px) {
    .carousel-item .three-img {
                width: 29%;
                margin-top: 30px;
                position: relative;
                height: 254px;
                bottom: 10px;  
                left: 34%;
    }
}

@media screen and (min-width: 1300px) {
/* body{
    background-color: #02182D;
} */

.nav-links {
    width: 68%;
    font-size: 24px;
    text-align: center;
    margin-bottom: 0px;
}

.logo_image{
    margin-left: 20px;
}

    /*firsrt section*/
    .header-content {
        padding: 0 1rem;
    }
    .header-bg {
        min-height: 61vh;
    }
    
    .text-center{
        margin-top: 60px;
        /* padding-top: 30px; */
        padding-left: 60px;
    }
    .text-center p{
        font-size: 26px;
        margin-left: 0%;
    }
    .text-center h2{
        font-size: 40px;
        margin-bottom: 3%;
        margin-left: 0%;
    }
    .startbtn {
        padding: 12px 30px;
        font-size: 25px;
        margin-right: 60%;
    }
    .homesection-image {
        width: 65%;
        margin-top: 43px;
    }
    .homesection-image {
        margin-top: 44px;
    }

    .container-fluid {
        max-height: 400px; /* Adjust this height as needed */
        overflow-y: auto;
      }
      


    /* second section */
    .aboutsection-image-all {
        margin-top: 200px;
    }
    .aboutsection-image {
        width: 32rem;
        margin-left: 55px;
    }
    .aboutsection {
        min-height: 97vh;
    }

    .about-text-center {
        margin-top: 7%;
    }
    .about-text-center h1{
        margin-left: 0%;
        font-size: 65px;
    }
    .about-text-center h3{
        font-size: 37px;
        font-weight: 600;
        margin-left: 0%;
    }
    .about-text-center p{
        font-size: 25px;
        margin-left: 0%;
    }
    .startbtn-about {
        padding: 12px 30px;
        background: linear-gradient(to bottom, #4D0006, #B60014 );
        color: white;
        font-size: 25px;
        font-weight: 600;
        margin-left: 0%;
        border-radius: 0px;
    }



    .servicecontent {
        height: 24vh;
    }

    .service-content p {
        font-size: 20px;
        color: black;
    }
    .h4, h4 {
        font-size: 30px;
    }

    .servicesection-image1 {
        margin-top: 40px;
        display: flex;
        height: fit-content;
    }
    .servicesection-image {
        width: 100px;
    }
    .servicesection-text1 {
        font-size: 34px;
        margin: 10px 20px 20px;
        color: white;
    }

    .servicesection-image2 {
        margin-top: 40px;
        display: flex;
        height: fit-content;
    }
    .servicesection-text2 {
        font-size: 34px;
        margin: 22px 12px 10px;
        color: white;
    }

    .servicesection-image3 {
        margin-top: 40px;
        display: flex;
        height: fit-content;
    }
    .servicesection-image-tech {
        width: 100px;
        height: 50%;
    }


    .servicesection-text3 {
        font-size: 34px;
        margin: 10px 20px 20px;
        color: white;
    }
    .servicesection-image{
        width: 100%;
        height: 50%;
    }


    .portfolio-content {
        padding: 0px;
        margin: 0px;
    }
    .portfolio-content p {
        font-size: 65px;
        font-weight: 700;
    }
    .portfolio-content h3 {
        font-size: 40px;
        font-weight: 600;
    }


    /*Today 12-3-24*/
    .portfolio-lap {
        /* width: 63rem;
        margin-left: 5%;
        margin-top: 0px; */

        /* width: 63rem;
        margin-left: 13%;
        margin-top: -28px; */
        
        display: block;
        margin-left: 13%;
        margin-right: 50%;
        width: 80%;
    }
    .portfolio-bulb {
        margin-top: -13%;
        margin-left: 70px;
        width: 10rem;
    }
    .portfolio-slat {
        /* width: 10rem;
        margin-top: 28%;
        margin-right: 80px;
        float: right; */
        width: 8rem;
        margin-top: -10%;
        margin-right: 50px;
        float: right;                       
    }                               

    .startbtn-protfolio {
        padding: 12px 30px;
        margin-left: 43.5%;
        margin-bottom: 60px;
        margin-top: -14%;
        font-size: 25px;

    }


/*fifth section */

.ourservice-header{
    background-repeat: no-repeat;
    background-size:cover ;
}

    .ourservice-alert{
        /* margin-bottom: -222px;
        margin-right: 75%;
        margin-top: 4%;
        width: 12rem; */
        margin-bottom: -230px;
        margin-right: 70%;
        margin-top: 0%;
        width: 10rem;
    }
    .ourservice-seo {
        /* margin-left: 75%;
        width: 10rem;
        margin-top: -13%; */
        margin-left: 80%;
        width: 9rem;
        margin-top: -18%;
    }
    .ourservice-content h3 {
        font-size: 60px;
        font-weight: 600;
    }
    .para{
        font-size: 32px;
        font-weight: 600;
        padding-top: 0px;
    }
    .ourservice-box{
        padding: 10px;
    }

    .ourservices-box1 {
        /* margin: 40px;
        padding: 38px; */
        margin: 27px;
        padding: 30px;
        border-radius: 5px;
    }
    .service-png{
        margin-left: 0px;
        /* margin-top: 50px; */
    }



/*sixth section */
   

    .testimon-content h3 {
        font-size: 60px;
        padding-top: 30px;
        font-weight: 600;
    }

    .testimon-content p {
        font-size: 35px;
        font-weight: 500;
    }
    .review1 p{
        font-size: 17px;
    }
    .review1 h5 {
        font-size: 27px;
    }

    .testimon-msgpng{
        margin-left: 80%;
        width: 10rem;
        margin-top: -7%;
    }
    .testimon-review {
        margin: 44px 220px;
        position: relative;
        left: 0px;
    }
    .review1{
      margin:  85px 25px 20px 33px
    }
    .review-content{
        font-size: 18px;
    }
    .testimon-1stpng {
        margin-top: 50px;
        margin-left: 0px;
        padding: 25px;
    }




    .quote-png1 {
        /* margin-top: -248px;
        width: 14rem;
        margin-right: 80px;
        height: fit-content; */

        margin-top: -280px;
        width: 15rem;
        margin-right: 79px;
        height: fit-content;
    }
    .quote-content h2{
        font-size: 55px;
        font-weight: 600;
        margin-bottom: 50px;
        padding-top: 50px;
    }
    .quote-content span{
        font-size: 48px;
    }
    .startbtn-quote {
        padding: 12px 30px;
        margin: 40px;
        font-size: 25px;
        color: white;
    }
    .client-logos{
        margin: 0px;
        padding: 0px;
    }
    .client-logos img {
        width: 10%;
        margin: 10px;
    }



    /* Ninth section */
    .footer-header {
        background-size: cover;
        background-repeat: no-repeat;
    }
    .footer-content {
        padding: 0px;
    }
    .footer-logo {
        width: 30%;
        margin-top: 20px;
    }

    .footer-logo p {
        /* text-align: left;
        font-size: 20px;
        padding: 5px 25px;
        margin-left: 20px; */
        font-weight: 500;
        text-align: left;
        font-size: 16px;
        padding: 0px 0px;
        margin-left: 15px;
    }
    .footer-logo-png {
        /* width: 16rem; */
        margin-bottom: 0px;
        /* padding: 0px 0px; */
        margin-right: 30px;
    }



    .footer-links {
        width: 100%;
        margin-right: 0px;
        display: flex;
        justify-content: space-between;
    }
    .footer-quicklink{
        padding: 0px;
        width: 25%;
        margin-top: 15px;
    } 
    .footer-ql-ul {
        font-size: 22px;
        font-weight: 600;
    }
    /* .footer-ql-ul li {
        font-size: 21px;
        font-weight: 500;
    } */



    .footer-service{
        padding: 0px;
        width: 30%;
        margin-top: 15px;
    } 
    .footer-service-ul {
        font-size: 30px;
        font-weight: 600;
    }
    .footer-service-ul li {
        font-size: 21px;
        font-weight: 500;
    }


    .footer-contact1{
        padding: 0px;
        margin-top: 15px;
    }
    .con-head {
        font-size: 30px;
        font-weight: 600;
    }


    .info-footer .InfoBoxfooter div p {
        color: black;
        font-size: 21px;
        margin-left: 0px;
    }
    .info-footer .InfoBoxfooter div a {
        color: black;
        text-decoration: none;
        font-size: 21px;
        margin-left: 0px;
    }

    .copyright p {
        font-size: 21px;
        color: white;
        margin-top: 20px;
    }

}


@media screen and (min-width: 1200px) and (max-width:1300px){

    /*firsrt section*/
    .header-content {
        padding: 0 1rem;
    }
    .header-bg {
        min-height: 61vh;
    }
    .digital-header-bg {
        min-height: 61vh;
    }
    .text-center{
        margin-top: 60px;
        padding-top: 30px;
        padding-left: 60px;
    }
    .text-center p{
        font-size: 20px;
        margin-left: 11%;
    }
    .text-center h2{
        font-size: 38px;
        margin-bottom: 3%;
        margin-left: 10%;
    }
    .startbtn {
        font-size: 20px;
        margin-right: 41%;
    }
    .homesection-image {
        width: 65%;
        margin-top: 43px;
    }
    .homesection-image {
        margin-top: 44px;
    }

    /* second section */
    .aboutsection-image-all {
        margin-top: 200px;
    }
    .aboutsection-image {
        width: 30rem;
        margin-left: 0%;
    }
    .aboutsection {
        min-height: 97vh;
    }

    .about-text-center {
        margin-top: 10%;
    }
    .about-text-center h1{
        margin-left: 0%;
        font-size: 60px;
    }
    .about-text-center h3{
        font-size: 30px;
        font-weight: 550;
        margin-left: 0%;
    }
    .about-text-center p{
        font-size: 22px;
        margin-left: 0%;
    }
    .startbtn-about {
        padding: 10px 30px;
        background: linear-gradient(to bottom, #4D0006, #B60014 );
        color: white;
        font-size: 20px;
        font-weight: 600;
        margin-left: 0%;
        border-radius: 0px;
    }

    .servicecontent {
        height: 26vh;
    }

    .service-content p {
        font-size: 20px;
        color: black;
    }
    .h4, h4 {
        font-size: 30px;
    }

    .servicesection-image1 {
        margin-top: 30px;
        display: flex;
        height: fit-content;
    }
    .servicesection-image2 {
        margin-top: 30px;
        display: flex;
        height: fit-content;
    }
    .servicesection-image3 {
        margin-top: 30px;
        display: flex;
        height: fit-content;
    }


    .portfolio-content {
        padding: 0px;
        margin: 0px;
    }
    .portfolio-content p {
        font-size: 63px;
        font-weight: 700;
    }
    .portfolio-content h3 {
        font-size: 35px;
        font-weight: 600;
    }


    /*Today 12-3-24*/
    .portfolio-lap {
        /* width: 63rem;
        margin-left: 5%;
        margin-top: 0px; */

        /* width: 63rem;
        margin-left: 13%;
        margin-top: -28px; */

        display: block;
        margin-left: 13%;
        margin-right: 50%;
        width: 80%;
    }
    .portfolio-bulb {
        margin-top: -13%;
        margin-left: 70px;
        width: 10rem;
    }
    .portfolio-slat {
        /* width: 10rem;
        margin-top: 28%;
        margin-right: 80px;
        float: right; */
        width: 8rem;
        margin-top: -10%;
        margin-right: 50px;
        float: right;                       
    }                               

    .startbtn-protfolio {
        padding: 10px 30px;
        margin-left: 43.5%;
        margin-bottom: 60px;
        margin-top: -14%;
    }


/*fifth section */

.ourservice-header{
    background-repeat: no-repeat;
    background-size:cover ;
}

    .ourservice-alert{
        /* margin-bottom: -222px;
        margin-right: 75%;
        margin-top: 4%;
        width: 12rem; */
        margin-bottom: -230px;
        margin-right: 70%;
        margin-top: 0%;
        width: 10rem;
    }
    .ourservice-seo {
        /* margin-left: 75%;
        width: 10rem;
        margin-top: -13%; */
        margin-left: 80%;
        width: 9rem;
        margin-top: -18%;
    }
    .ourservice-content h3 {
        font-size: 60px;
        font-weight: 600;
    }
    .para{
        font-size: 32px;
        font-weight: 600;
        padding-top: 0px;
    }
    .ourservice-box{
        padding: 10px;
    }

    .ourservices-box1 {
        /* margin: 40px;
        padding: 38px; */
        margin: 27px;
        padding: 30px;
        border-radius: 5px;
    }
    .service-png{
        margin-top: 0px;
        margin-top: 25px;
    }



/*sixth section */
    .testimon-header {
        min-height: 89vh;
    }

    .testimon-content h3 {
        font-size: 60px;
        padding-top: 30px;
        font-weight: 600;
    }

    .testimon-content p {
        font-size: 35px;
        font-weight: 500;
    }
    .review1 p{
        font-size: 18px;
    }
    .review1 h5 {
        font-size: 25px;
    }

    .testimon-msgpng  {
        margin-left: 63%;
        width: 10rem;
        margin-top: -10%;
    }
    .testimon-review {
        margin: 44px 175px;
        position: relative;
        left: 0px;
    }
    .review1{
      margin:  85px 25px 20px 33px
    }
    .review-content{
        font-size: 18px;
    }
    .testimon-1stpng {
        margin-top: 50px;
        margin-left: 0px;
        padding: 25px;
    }




    .quote-png1 {
        /* margin-top: -248px;
        width: 14rem;
        margin-right: 80px;
        height: fit-content; */

        margin-top: -280px;
        width: 15rem;
        margin-right: 79px;
        height: fit-content;
    }
    .quote-content h2{
        font-size: 50px;
        font-weight: 600;
        margin-bottom: 50px;
        padding-top: 50px;
    }
    .quote-content span{
        font-size: 43px;
    }
    .startbtn-quote {
        padding: 10px 30px;
        margin: 40px;
        color: white;
    }
    .client-logos{
        margin: 0px;
        padding: 0px;
    }
    .client-logos img {
        width: 10%;
        margin: 10px;
    }



    /* Ninth section */
    .footer-content {
        padding: 0px;
    }
    .footer-logo {
        width: 30%;
        margin-top: 20px;
    }

    .footer-logo p {
        text-align: left;
        font-size: 20px;
        padding: 0px 0px;
        margin-left: 35px;
        color: #666F74;
    }
   



    .footer-links {
        width: 66%;
        margin-right: 0px;
        display: flex;
        justify-content: space-between;
    }
    .footer-quicklink{
        padding: 0px;
        width: 25%;
        margin-top: 15px;
    } 
    .footer-ql-ul {
        font-size: 18px;
        font-weight: 600;
    }
    .footer-ql-ul li {
        font-size: 15px;
        font-weight: 600;
    }



    .footer-service{
        padding: 0px;
        width: 30%;
        margin-top: 15px;
    } 
    .footer-service-ul {
        font-size: 30px;
        font-weight: 600;
    }
    .footer-service-ul li {
        font-size: 20px;
        font-weight: 500;
    }


    .footer-contact1{
        padding: 0px;
        margin-top: 15px;
    }
    .con-head {
        font-size: 30px;
        font-weight: 600;
    }


    .info-footer .InfoBoxfooter div p {
        font-size: 20px;
        margin-left: 0px;
    }
    .info-footer .InfoBoxfooter div a {
        text-decoration: none;
        font-size: 20px;
        margin-left: 0px;
    }

  } 

  @media screen and (min-width: 565px) and (max-width: 991px) {
    .carousel-container1 {
        background-image: url(imagesold/4thsec_laptop.png);
        background-size: 80% 100%; /* Cover the entire container */
        background-position: center; /* Center the background image */
        background-repeat: no-repeat;
        margin-left: 30px;
    }

  }

  @media screen and (min-width: 991px) and (max-width: 1024px) {

    .carousel-container1 {
        background-image: url(imagesold/4thsec_laptop.png);
        background-size: 80% 100%; /* Cover the entire container */
        background-position: center; /* Center the background image */
        background-repeat: no-repeat;
        margin-left: 30px;
    }

  }

  @media screen and (min-width: 320px) and (max-width: 565px) {
    .carousel-container1 {
        background-image: url(imagesold/4thsec_laptop.png);
        background-size: 100% 100%; /* Cover the entire container */
        background-position: center; /* Center the background image */
        background-repeat: no-repeat;
        margin-left: 60px;
        margin: 0px;
    }

    /* .mx-auto {
        margin-left: 0px;
        margin-right: 0px;
    }

    .bulb {
        height: 40%;
        width: 40%;
        position: relative;
        left: 0px;
        top: 10%;
    }

    .slat {
        height: 40%;
        width: 40%;
        position: relative;
        left: 0px;
        top: 10%;
    }

    .laptop {
        height: 40%;
        width: 40%;
        position: relative;
        left: 0px;
        top: 10%;
    } */
  }
 

  @media screen and (min-width: 992px) and (max-width: 1199px) {
 
    .header-bg {
        min-height: 48vh;
    }
    .digital-header-bg {
        min-height: 54vh;
    }
    .about-text-center h1 {
        margin-left: 16%;
    }
    .aboutsection-image-all {
        width: 50%;
        margin-top: 40px;
    }
    .aboutsection-image {
        width: 24rem;
        margin-left: 10%;
    }
    .about-text-center {
        margin-top: 7%;
    }
    .about-text-center h3 {
        font-size: 24px;
    }
    .aboutsection {
        min-height: 60vh;
    }
    
    .carousel-container1 {
        background-image: url(imagesold/4thsec_laptop.png);
        background-size: 80% 100%; /* Cover the entire container */
        background-position: center; /* Center the background image */
        background-repeat: no-repeat;
        margin-left: 60px;
    }


    .text-center {
        margin-top: 45px;
        padding-left: 60px;
    }

    .img-fluid-home {
        max-width: 100%;
        height: 300px;
    }

        .text-center p{
            font-size: 17px;
            margin-left: 0%;
        }
        .text-center h2{
            font-size: 33px;
            margin-bottom: 3%;
            margin-left: 0%;
        }
        .startbtn {
            font-size: 16px; 
            margin-right: 50%;
        }
    
    
        .homesection-image {
            width: 65%;
            margin-top: 33px;
            margin-left: 55px;
            margin-bottom: 19px;
            height: 300px !important;
            width: 300px !important;
        }
        .homesection-image-all{
            width: 50%;
        }




    /*third section*/

    .servicecontent {
        height: 22vh;
    }
    .service-content h4{
        margin-top: 10px;
    }
    .servicesection-image1 {
        margin-top: 33px;
    }
    .servicesection-text1 {
        font-size: 38px;
        margin: 10px 22px 20px;
    }
    .servicesection-image2 {
        margin-top: 30px;
    }
    .servicesection-text2 {
        font-size: 37px;
        margin: 15px 12px 10px;
    }
    .servicesection-image3 {
        margin-top: 30px;
    }
    .servicesection-text3 {
        font-size: 38px;
        margin: 10px 20px 20px;
    }


    /*fourth section*/

    .portfolio-bg{
        background-image: url(imagesold/4thBG.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
         
    }
    .portfolio-content p {
        text-align: center;
        font-size: 53px;
        font-weight: 600;
        color: #CB0C1E;
        margin-bottom: 0px;
    }
    .portfolio-content h3 {
    text-align: center;
    font-size: 35px;
    color: #02182D;
    font-weight: 500;
    }


    .portfolio-bulb {
        margin-top: -12%;
        margin-left: 35px;
        width: 9rem;
    }

    .portfolio-lap {
        width: 80%;
        margin-top: 0px; 
        margin-left: 120px;
    }


    .portfolio-slat {
        /* width: 9rem;
        float: right; */
        margin-top: -10%;
        margin-right: 60px;
    }

    
    .startbtn-protfolio {
        padding: 10px 30px;
        font-size: 20px;
        font-weight: 600;
        margin-left: 38%;
        margin-top: -35px;
        margin-bottom: 52px;
    }

    

    /*fifth section*/
    .ourservice-header{
        min-height: 87vh;
        background-image: url(imagesold/5thsecBG.jpg);
        background-repeat: no-repeat;
        background-size:cover ;
        position: relative;
    }
    .ourservice-box {
        display: grid;
        grid-template-columns: auto auto;
        padding: 30px;
        margin-top: -30px;
    }
    .ourservices-box1 {
        margin: 15px;
        padding: 0px;
        border-radius: 5px;
    }
    .ourservice-content h3 {
        font-size: 50px;
    }
    .para {
        font-size: 30px;
        padding-top: 0px;
        font-weight: 600;
    }
    .ourservice-alert {
        margin-right: 77%;
        width: 9rem;
        margin-top: 0%;
        margin-bottom: -190px;
    }
    .ourservice-seo {
        margin-left: 74%;
        width: 9rem;
        margin-top: -26%;
    }

    /* .service-png {
        margin-left: 30px;
        margin-top: 45px;
    } */
    .service-content p {
        font-size: 15px;
    }

    /* sixth section*/
    .testimon-header {
        min-height: 74vh;
    }
    .testimon-content h3 {
        padding-top: 10px;
        font-size: 45px;
        font-weight: 600;
        color: #C80114;
    }
    
    .testimon-content p {
        font-size: 30px;
        margin-top: 0px;
    }
    .testimon-msgpng {
        margin-left: 70%;
        width: 7rem;
        margin-top: -15%;
    }
    .testimon-review {
        padding: 0px 0px;
        position: relative;
        left: 0px;
    }
    .review1 h5 {
        font-size: 23px;
        font-weight: 600;
        margin-top: 10px;
    }
    .review1 p {
        font-size: 20px;
        font-weight: 100;
        margin-top: 10px;
    }

    /*seventh section*/
    .quote-content h2 {
        padding-top: 29px;
        font-size: 40px;
        font-weight: 650;
        line-height: 1;
    }
    .quote-png1 {
        margin-top: -222px;
        width: 13rem;
        margin-right: 45px;
        height: fit-content;
    }
    .startbtn-quote {
        padding: 10px 35px;
        margin: 25px;
        font-size: 25px;
        font-weight: 600;
        border: white;
        margin-top: 72px;
        color: white;
    }

    /*eight section*/
    .client-logos{
        margin: 0px;
        padding: 0%;
    }
    .client-logos img {
        width: 12%;
        margin: 20px;
    }


    /*Ninth Section*/
    .footer-header{
        background-image: url(imagesold/footerbg.png);
        background-size: cover;
        background-repeat: no-repeat;
    }
    .footer-content {
        padding: 0px;
    }
    .footer-logo {
        width: 35%;
        margin-top: 0px;
    }
    .footer-logo-png {
        width: 10rem;
        margin-bottom: 0px;
        padding: 0px 0px;
        margin-right: 30px;
    }
    .footer-logo p {
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        padding: 10px 20px;
        margin-left: 0px;
    }

    ol, ul {
        padding-left: 0rem;
    }
    .footer-links {
        width: 65%;
    }
    .footer-quicklink {
        margin-top: 20px;
        padding: 0px;
        width: 30%;
    }
    .footer-ql-ul {
        font-size: 18px;
        font-weight: 600;
    }
    .footer-ql-ul li {
        font-size: 16px;
        /* font-weight: normal; */
    }


    .footer-service {
        margin-top: 20px;
        padding: 0px;
        width: 34%;
    }
    .footer-service-ul {
        font-size: 27px;
        font-weight: 600;
    }
    .footer-service-ul li {
        font-size: 20px;
        font-weight: normal;
    }


    .footer-contact1 {
        margin-top: 20px;
        padding: 0px;
        width: 50%;
    }
    .con-head {
        font-size: 27px;
        font-weight: 600;
    }



    }
















    @media screen and  (min-width: 768px) and (max-width: 991px) {





/* @media  only screen and (max-width:768px){ */
    /* body{
        display: none   checking only
    } */

/* first section */

.nav-links{
    position: absolute;
    flex-direction: column;
    /* background-color: rgb(106, 106, 153); */
    left: -10rem;
    top: 12vh;
    height: 50vh;
    width: 0%;
    padding: 2rem 0;
    transition: all 900ms;
    z-index: 1;

    }
    .nav-show {
        width: 100%;
        left: 0px;
        top: 7rem;
        background-color: #FA7070;
        border-radius: 30px;
    }
    .burger{
        display:  block;
    } 


.header-bg{
    background-size: cover;
}
.digital-header-bg {
    background-size: cover;
}
.homecontent {
    width: 100%;
    display: inline;
}
.homesection-image {
    width: 70%;
    margin-top: 10px;
    margin-left: 260px;
    margin-bottom: 10px;
}


.homesection-image-all {
    width: 60%;
}

    .header-content{
        height: 17vh;
        padding: 0 2rem;
    }
    .logo {
        height: 80px;
    }

    .home-content{
        width: 100%;
    }
    .text-center {
        width: 100%;
        padding: 15px;
        margin-top: 28px;
    }
        .text-center p{
            font-size: 20px;
            margin-left: 0%;
            text-align: center;
        }
        .text-center h2{
            font-size: 38px;
            margin-bottom: 2%;
            margin-left: 0%;
            text-align: center;
        }
        .startbtn {
            padding: 5px 20px;
            font-size: 20px;
            margin-right: 0%;
        }
    
        




/* second section */

        .header-bg {
            min-height: 41vh;
        }
        .digital-header-bg {
            min-height: 41vh;
        }
        .aboutsection {
            min-height: 65vh;
        }
        .aboutsection-image {
            width: 20rem;
            margin-left: 10%;
            
        }
        .aboutsection-image-all {
            margin-top: 140px;
        }
        .about-text-center h1 {
            margin-left: 0%;
            font-size: 45px;
        }
        .about-text-center h3 {
            margin-left: 0%;
            color: #B90112;
            font-size: 20px;
            font-weight: 600;
        }
        .about-text-center p {
            margin-left: 0%;
            font-size: 18px;
            font-weight: 500;
        }
        .startbtn-about {
            margin-left: 0%;
            font-size: 18px;
            font-weight: 500;
        }
        .about-text-center {
            margin-top: 22px;
        }



/* third section */
.servicesection {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.servicecontent {
    height: 18vh;
}
.servicesection-image1 {
    margin-top: 20px;
}
.servicesection-text1 {
    font-size: 30px;
    margin: 16px 15px 20px;
}
.servicesection-image2 {
    margin-top: 20px;
}
.servicesection-text2 {
    font-size: 30px;
    margin: 22px 10px 15px;
}
.servicesection-image3 {
    margin-top: 20px;
}
.servicesection-text3 {
    font-size: 30px;
    margin: 16px 15px 20px;
}

/*Fouth section*/
.portfolio-content p {
    font-size: 46px;
}
.portfolio-content .passionate {
    font-size: 28px !important;
}
.portfolio-content h3 {
    font-size: 30px;
}
.portfolio-bulb {
    margin-top: -19%;
    margin-left: 35px;
    width: 7rem;
}


.portfolio-lap {
    width: 74%;

}


.portfolio-slat {
    width: 7rem;
    margin-top: -12%;
    float: right;
    margin-right: 45px;
}


.startbtn-protfolio {
    padding: 5px 25px;
    font-size: 22px;
    font-weight: 500;
    margin-left: 36%;
    margin-bottom: 37px;
    margin-top: -15px;
}



/*fifth section */

.ourservice-header{
    min-height: 100vh;
    background-image: url(imagesold/5thsecBG.jpg);
    background-repeat: no-repeat;
    background-size:cover ;
    position: relative;
}
.ourservice-content h3 {
    font-size: 40px;
}
.para {
    font-size: 28px;
    padding-top: 0px;
    font-weight: 500;
}
.ourservice-alert {
    width: 7rem;
    margin-top: -6%;
}
.ourservice-seo {
    width: 7rem;
    margin-top: -30%;
}
.ourservice-box {
    display: grid;
    padding: 0px;
    grid-template-columns: auto;
    align-items: start;
    justify-content: space-around;
}
.ourservices-box1 {
    margin: 20px;
    padding: 20px;
    border-radius: 5px;
}
.service-png {
    margin-left: 20px;
    margin-top: 25px;
}


/* sixth section*/
.testimon-header { 
    min-height: 36vh;
}
.testimon-content h3 {
    font-size: 40px;
    font-weight: 600;
    padding-top: 0px;
    color: #C80114;
}
.testimon-content p {
    font-size: 28px;
    margin-top: 0px;
}
.testimon-msgpng {
    margin-top: -15%;
    margin-left: 70%;
    width: 7rem;
}
.testimon-review {
    padding: 10px 10px;
    position: relative;
    left: 0px;
    margin-bottom: 50px;
}
.quatationpng {
    margin-left: -10px;
    height: 20px;
}
.starpng {
    margin-right: 0px;
    height: 20px;
}
.review1 h5 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;

}
.testimon-1stpng {
    margin-top: 40px;
    margin-left: 20px;
    width: 4rem;
}
.review1 {
    margin: 73px 20px 5px 30px;
}
.review1 p {
    font-size: 14px;
}



/*seventh section*/

.quote-content h2 {
    padding-top: 10px;
    font-size: 38px;
    font-weight: 500;
    line-height: 1;
}
.quote-content span {
    font-size: 32px;
}
.quote-png1 {
    margin-top: -158px;
    width: 9rem;
    margin-right: 23px;
    height: fit-content;
}
.startbtn-quote {

    padding: 5px 25px;
    margin: 23px;
    font-size: 22px;
    font-weight: 500;
    color: white;
}

/*eigth section */

.client-logos {
    margin: 0px;
    padding: 0px;
}
.client-logos img {
    width: 11%;
    margin: 20px;
}


.footer-header{
    background-image: url(imagesold/footerbg.png);
    background-size: cover;
    background-repeat: no-repeat;
}
.footer-content {
    padding: 0px;
    text-align: center;
    display: flow;
}
.footer-logo {
    width: 100%;
    margin-top: 0px;
}
.footer-logo-png {
    width: 10rem;
    margin-bottom: 0px;
}
.footer-logo p {
    text-align: center;
    font-size: 18px;
    font-weight: 200;
    padding: 0px 0px;
    margin-left: 5px;
}

.add-content {
    font-size: 15px;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 19px;
}

.footer-links {
    width: 100%;
    display: flex;
    justify-content: space-around;
    text-align: left;
}
.footer-quicklink {
    margin-top: 0px;
    padding: 0px;
    width: 25%;
    margin-left: 0px;
}
.footer-ql-ul {
    font-size: 27px;
    font-weight: 600;
    padding-left: 0px;
}

.footer-quicklink2 {
    margin-top: 18px;
    padding: 22px;
    width: 50%;
    padding-left: 0px;
}

.footer-quicklink3 {
    margin-top: 18px;
    padding: 22px 0px 22px 0px;
    width: 70%;
}

.footer-ql-ul li {
    font-size: 15px;
}



.footer-service {
    margin-top: 0px;
    padding: 0px;
    width: 30%;
    margin-left: 0px;
}
.footer-service-ul {
    font-size: 25px;
    font-weight: 600;
}
.footer-service-ul li {
    font-size: 15px;
}

.footer-contact1 {
    margin-top: 0px;
    padding: 0px;
    width: 37%;
    margin-right: 0px;
}
.con-head {
    font-size: 25px;
    font-weight: 600;
}
.info-footer .InfoBoxfooter div p {
    font-size: 15px;
    font-weight: 600;
    margin-left: 0px;
}
.info-footer .InfoBoxfooter div a {
    font-size: 17px;
    font-weight: 500;
    margin-left: 5px;
}
.info-footer .InfoBoxfooter div span {
    height: 35px;
    margin-left: -8px;
}


.copyright p {
    font-size: 20px;
    color: white;
    margin-top: 20px;
    text-align: center;
}


}



/* .nav-show{
    width: 100%;
    left: 0px;
    
} */

.textareahide{
    left: -20rem;
}

/* .testimon-header h3 {
    display: flex;
    justify-content: center;
    font-size: 50px;
}

.testimon-header p {
    display: flex;
    justify-content: center;
    font-size: 30px;
} */


@media (min-width: 481px) and (max-width: 565px) {
    
    .testimon-review {
        padding: 10px 10px;
        position: relative;
        left: 0%;
        margin: 10px;
    }
}

@media (min-width: 566px) and (max-width: 768px) {

    .testimon-review {
        padding: 10px 10px;
        position: relative;
        left: 0%;
        margin: 10px;
    }

    .testimon-header h3 {
        font-size: 35px !important;
    }

    .testimon-header p {
        font-size: 15px;
    }
}


@media (min-width: 481px) and (max-width: 767px){

    .nav-show {
        width: 100% !important;
        left: 0px;
        top: 7rem;
        background-color: #FA7070;
        border-radius: 30px;
    }

    .burger{
        display:  block;
    } 
    .logo {
        height: fit-content;
    }
    
    .header-content {
        height: 18vh;
        padding: 0 3rem;
    }



    .home-content{
        width: 100%;
    }
    .text-center {
        width: 100%;
        padding: 15px;
        margin-top: 28px;
    }
    .text-center p {
        font-size: 20px;
        margin-left: 0px;
        text-align: center;
    }
    .text-center h2 {
        font-size: 36px;
        margin-bottom: 3%;
        text-align: center;
        margin-left: 0px;
    }
    .startbtn {
        padding: 5px 15px;
        font-size: 20px;
        font-weight: 500;
        margin-right: 0px;
    }

    .header-bg{
        background-size: cover;
    }
    .digital-header-bg {
        background-size: cover;
    }
    .homecontent {
        width: 100%;
        display: inline;
    }
    .homesection-image-all {
        width: 100%;
    }
    /* .homesection-image {
        width: 70%;
        margin-top: 10px;
        margin-left: 260px;
        margin-bottom: 10px;
    } */
    .homesection-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
    
        
    
    
    /* second section */
    .aboutcontent {
        display: inline;
    }
    .aboutsection-image {
        padding-top: 25px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 60%;
        margin-bottom: 30px;
    }
    .aboutsection-image-all {
        margin-top: 0px;
        width: 100%;
    }
    .aboutsection {
        min-height: 80vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
    }
    .about-text-center {
        margin-top: 0px;
        width: 100%;
        margin-left: 0%;
    }
    .about-text-center h1 {
        margin-left: 0%;
        font-size: 45px;
        text-align: center;
    }
    .about-text-center h3 {
        font-size: 27px;
        font-weight: 700;
        margin-left: 0px;
        text-align: center;
    }
    .about-text-center p {
        font-size: 19px;
        font-weight: 200;
        margin-left: 0px;
        margin-bottom: 5px;
        padding: 7px;
        text-align: center;
    }

    .startbtn-about {
        /* padding: 5px 20px;
        margin-left: 36%;
        font-size: 20px;
        font-weight: 0;
        text-align: center; */

        padding: 5px 20px;
        font-size: 20px;
        font-weight: 0;
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
        margin-bottom: 20px;
        margin-top: 30px;
    }


    
    /* third section */
    .servicesection{
        background-size: cover;
        background-repeat: no-repeat;
    }
    .servicecontent {
        height: 16vh;
        justify-content: space-evenly;
    }
    .servicesection-image1 {
        display: flex;
        margin-top: 20px;
        margin-left: 0px;
    }
   

    .servicesection-image{
        width: 70%;
    }
    .servicesection-image-tech {
        width: 100px;
    }
    .servicesection-image2 {
        margin-top: 20px;
        display: flex;        
    }
    


    .servicesection-image3 {
        margin-top: 20px;
        /* display: inline; */
    }
    .servicesection-text3 {
        /* display: none; */
        display: flex;
    }

    
    /*Fouth section*/
    .portfolio-bg{
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
         
    }
    .portfolio-content p {
        font-size: 42px;
    }
    .portfolio-content h3 {
        font-size: 24px;
    }
    /* .portfolio-bulb {
        margin-top: -56%;
        margin-left: 35px;
        width: 5rem;
    } */
    .portfolio-bulb {
        /* margin-top: -63%;
        margin-left: 0px;
        width: 5rem; */

        margin-top: -16%;
        margin-left: 10px;
        width: 5rem;
    }
    .portfolio-lap {
        /* width: 75%;
        margin-left: 0%;
        margin-top: 0px; */
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
    }
    /* .portfolio-slat {
        width: 5rem;
        margin-top: -7%;
        float: right;
        margin-right: 10px;
    } */
    .portfolio-slat {
        /* width: 5rem;
        margin-top: -7%;
        float: right;
        margin-right: 70px; */

        width: 5rem;
        margin-top: -10%;
        float: right;
        margin-right: 44px;
    }
    
    .startbtn-protfolio {
        padding: 5px 20px;
        font-size: 20px;
        font-weight: 500;
        margin-left: 37%;
        margin-bottom: 30px;
        margin-top: -15px;
    }
    
    
    
    /*fifth section */
    
    .ourservice-header{
        min-height: 100vh;
        background-repeat: no-repeat;
        background-size:cover ;
        position: relative;
    }
    .ourservice-content h3 {
        font-size: 42px;
    }
    .para {
        font-size: 23px;
        padding-top: 0px;
        font-weight: 500;
    }
    .ourservice-alert {
        width: 4rem;
        margin-top: -25%;
    }
    .ourservice-seo {
        width: 5rem;
        margin-top: -40%;
        margin-left: 81%;
        margin-bottom: -161px;
    }
    .ourservice-box {
        display: grid;
        padding: 10px;
        grid-template-columns: auto;
        align-items: start;
        justify-content: space-around;
    }
    .service-content p {
        font-size: 15px;
    }

    .ourservices-box1 {
        margin: 22px;
        padding: 18px;
        align-items: center;
        border-radius: 5px;
    }
    .service-png {
        margin-left: 0px;
        margin-top: 0px;   
    }
    

    /* sixth section*/
    .testimon-header{ 
        background-repeat: no-repeat;
        background-size: cover;
    }
    .testimon-header { 
        min-height: 72vh;
    }
    .testimon-content h3 {
        font-size: 38px;
        font-weight: 600;
        padding: 0px;
        padding-left: 5px;
    }
    .testimon-content p {
        font-size: 25px;
        margin-top: 0px;
    }
    .testimon-msgpng {
        margin-left: 80%;
        width: 5rem;
        margin-top: -17%;
    }
    /* .testimon-review {
        padding: 10px 10px;
        position: relative;
        left: 20%;
        margin: 10px;
    } */
    .quatationpng {
        margin-left: -10px;
        height: 20px;
    }

    .starpng {
        margin-right: 0px;
        height: 20px;
    }
    .review1 h5 {
        font-size: 20px;
        font-weight: 600;
        margin-top: 10px;
    
    }
    .testimon-1stpng {
        width: 4rem;
        margin-top: 40px;
        margin-left: 10px;
    }
    .review1 {
        margin: 73px 20px 5px 30px;
    }
    .review1 p {
        font-size: 15px;
        margin-top: 5px;
    }
    
    

    
    /*seventh section*/

    .quote-header{
        background-size: cover;
    }
    
    .quote-content h2 {
        padding-top: 10px;
        font-size: 34px;
        font-weight: 500;
        line-height: 1;
    }
    .quote-content span {
        font-size: 29px;
    }
    .quote-png1 {
        margin-top: -90px;
        width: 8rem;
        margin-right: 30px;
        height: fit-content;
    }
    .startbtn-quote {
        padding: 5px 20px;
        margin: 23px;
        font-size: 20px;
        font-weight: 500;
        color: white;
    }
    

    /*eigth section */
    
    .client-logos {
        margin: 0px;
        padding: 0px;
    }
    .client-logos img {
        width: 10%;
        margin: 10px;
    }
    


    .footer-header{
        background-size: cover;
    }
    .footer-content {
        padding: 0px;
        text-align: center;
        display: inline;
    }
    .footer-logo {
        width: 100%;
        margin-top: 0px;
    }
    .footer-logo-png {
        width: 15rem ;
        margin-bottom: 0px;
    }
    .footer-logo p {
        text-align: center;
        font-size: 18px;
        font-weight: 200;
        padding: 0px 0px;
        margin-left: 0px;
    }

    .text-contents p {
        text-align: justify;
        font-size: 24px;
        letter-spacing: 0px;
        align-items: justify;
        margin-right: 18%;
        animation: headercontent 2s ease-in-out;
    }
    
    p {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 15px;
        font-weight: 600;
    }
    ol, ul {
        padding-left: 0rem;
    }
    
    .footer-links {
        width: 100%;
        display: inline;
    }
    .footer-quicklink {
        margin-top: 0px;
        padding: 0px;
        width: 25%;
        margin-left: 10px;
    }
    .footer-ql-ul {
        font-size: 27px;
        font-weight: 600;
        width: 20rem;
    }
    .footer-ql-ul li {
        font-size: 18px;
        font-weight: 500;
    }
    
    .footer-service {
        margin-top: 0px;
        padding: 0px;
        width: 30%;
        margin-left: 10px;
    }
    .footer-service-ul {
        font-size: 25px;
        font-weight: 600;
        width: 20rem;
    }
    .footer-service-ul li {
        font-size: 18px;
        font-weight: 500;
    }
    
    .footer-contact1 {
        width: 100%;
        margin-top: 0px;
        padding: 0px;
        margin-left: 10px;
    }
    .con-head {
        font-size: 25px;
        font-weight: 600;
        width: 20rem;
    }
    .info-footer .InfoBoxfooter div p {
        font-size: 17px;
        font-weight: 500;
        margin-left: 0px;
    }
    .info-footer .InfoBoxfooter div a {
        font-size: 18px;
        font-weight: 500;
        margin-left: 0px;
    }
    .info-footer .InfoBoxfooter div span {
        height: 35px;
        margin-left: -8px;
    }

    .social {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-left: 0px;
    }

    .copyright p {
        font-size: 20px;
        color: white;
        margin-top: 20px;
        text-align: center;
    }
}

/* .nav-show{
    width: 100%;
    left: 0px;
} */

.textareahide{
    left: -20rem;
}

@media (min-width: 480px) and (max-width: 780px) {
.nav-show {
    width: 100%;
    left: 0px;
    top: 6rem;
    background-color: #FA7070;
    border-radius: 30px;
    color: white;
    position: absolute;
    flex-direction: column;
    background-color: #FA7070;
    width: 100%;
    top: 12vh;
    height: 50vh;
    width: 0%;
    padding: 2rem 0;
    transition: all 900ms;
    z-index: 1;
    position: absolute;
    top: 20vh;
}
}

@media (min-width: 991px) and (max-width: 1300px) {
    .nav-show {
        left: 0px;
        top: 6rem;
        background-color: #FA7070;
        border-radius: 30px;
        color: white;
        position: absolute;
        flex-direction: column;
        background-color: #FA7070;
        top: 20vh;
        height: 50vh;
        width: 100%;
        padding: 2rem 0;
        transition: all 900ms;
        z-index: 1;
    }
    }

@media screen and  (min-width: 320px) and (max-width: 480px) {

    .nav-links{
        position: absolute;
        flex-direction: column;
        background-color: rgb(106, 106, 153);
        left: -10rem;
        top: 12vh;
        height: 50vh;
        width: 0%;
        padding: 2rem 0;
        transition: all 900ms;
        z-index: 1;
    }
    .nav-show {
        width: 100%;
        left: 0px;
        top: 6rem;
        background-color: #FA7070;
        border-radius: 30px;
        color: white;
    }

    .burger{
        display:  block;
    } 
    .logo {
        height: fit-content;
    }
    .header-content {
        height: 15vh;
        padding: 0 1rem;
    }



    .home-content{
        width: 100%;
    }
    .text-center {
        width: 100%;
        padding: 15px;
        margin-top: 28px;
    }
    .text-center p {
        font-size: 17px;
        margin-left: 0px;
        text-align: center;
    }
    .text-center h2 {
        font-size: 29px;
        margin-bottom: 3%;
        text-align: center;
        margin-left: 0px;
    }
    .startbtn {
        padding: 5px 10px;
        font-size: 15px;
        font-weight: 100;
        margin-right: 0px;
    }



    .header-bg{
        background-size: cover;
    }
    .digital-header-bg {
        background-size: cover;
    }
    .homecontent {
        width: 100%;
        display: inline;
    }
    .homesection-image-all {
        width: 100%;
    }
    .homesection-image {
        width: 70%;
        margin-left: 60px;
    }
    
        
    
    
    /* second section */
    .aboutcontent {
        display: inline;
    }
    .aboutsection-image {
        width: 100%;
        margin-left: 50%;
        padding-top: 12px;
    }
    .aboutsection-image-all {
        margin-top: 0px;
    }

    .header-bg {
        min-height: 41vh;
    }
    .aboutsection {
        min-height: 68vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
    }
    .about-text-center {
        margin-top: 0px;
        width: 100%;
        margin-left: 0%;
    }
    .about-text-center h1 {
        margin-left: 0%;
        font-size: 40px;
        text-align: center;
    }
    .about-text-center h3 {
        font-size: 20px;
        font-weight: 400;
        margin-left: 0px;
        text-align: center;
    }
    .about-text-center p {
        font-size: 16px;
        font-weight: 200;
        margin-left: 0px;
        margin-bottom: 5px;
        padding: 13px;
        text-align: center;
    }

    .startbtn-about {
        padding: 5px 5px;
        margin-left: 36%;
        font-size: 13px;
        font-weight: 0;
        text-align: center;
        margin-bottom: 20px;
    }


    
    /* third section */
    .servicesection{
        background-size: cover;
        background-repeat: no-repeat;
    }
    .servicecontent {
        height: 16vh;
        justify-content: space-evenly;
    }
    .servicesection-image1 {
        margin-top: 20px;
        margin-left: 0px;
        display: inline;
    }
    
    .servicesection-image{
        width: 70%;
    }
    .servicesection-image-tech {
        width: 65%;
    }


    .servicesection-image2 {
        margin-top: 13px;
        display: inline;
    }
    .servicesection-text2 {
        display: none;
    }

    .servicesection-image3 {
        margin-top: 20px;
        display: inline;
    }
    .servicesection-text3 {
        display: none;
    }

    .servicesection-image{
        width: 70%;
    }

    
    /*Fouth section*/
    .portfolio-bg{
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
         
    }

    .portfolio-content .passionate {
        font-size: 20px !important;
    }

    .portfolio-content p {
        font-size: 42px;
    }
    .portfolio-content h3 {
        font-size: 24px;
    }
    .portfolio-bulb {
        margin-bottom: 0%;
        margin-left: 7px;
        width: 3rem;
        margin-top: -5%;
    }
    .portfolio-lap {
        width: 100%;
        margin-left: 0%;
        margin-top: -20px;
    }
    .portfolio-slat {
        width: 3rem;
        margin-top: -10%;
        float: right;
        margin-right: 10px;
    }
    
    .startbtn-protfolio {
        padding: 5px 5px;
        font-size: 13px;
        font-weight: 500;
        margin-left: 34%;
        margin-bottom: 30px;
        margin-top: -15px;
    }
    
    
    
    /*fifth section */
    
    .ourservice-header{
        min-height: 100vh;
        background-repeat: no-repeat;
        background-size:cover ;
        position: relative;
    }
    .ourservice-content h3 {
        font-size: 42px;
    }
    .para {
        font-size: 18px;
        padding-top: 0px;
        font-weight: 500;
    }
    .ourservice-alert {
        width: 4rem;
        margin-top: -37%;
        margin-right: 80%;
        margin-bottom: -177px;
    }
    .ourservice-seo {
        width: 4rem;
        margin-top: -37%;
        margin-left: 77%;
        margin-bottom: -155px;
    }
    .ourservice-box {
        display: grid;
        padding: 10px;
        grid-template-columns: auto;
        align-items: start;
        justify-content: space-around;
    }
    .service-content {
        margin-left: 13px;
    }
    .service-content h4{
        font-size: 15px;
        font-size: 600;
    }
    .service-content p {
        font-size: 10px;
    }

    .ourservices-box1 {
        margin: 25px;
        padding: 10px;
        border-radius: 5px;
    }
    .service-png {
        margin-left: 0px;
        margin-top: 12px;
        height: 37px;
    }
    

    /* sixth section*/
    .testimon-header{ 
        background-repeat: no-repeat;
        background-size: cover;
    }
    .testimon-header { 
        min-height: 80vh;
    }
    .testimon-content h3 {
        font-size: 30px;
        font-weight: 600;
        padding: 0px;
        padding-top: 12px;
    }
    .testimon-content p {
        font-size: 16px;
        margin-top: 0px;
    }
    .testimon-msgpng {
        margin-left: 70%;
        width: 3rem;
        margin-top: -15%;
    }
    .testimon-review {
        margin: 0px 40px;
        position: relative;
        left: 0px;
        margin-top: 20px;
    }
    .quatationpng {
        margin-left: -10px;
        height: 11px;
    }

    .starpng {
        margin-right: 0px;
        height: 11px;
    }
    .review1 h5 {
        font-size: 17px;
        font-weight: 400;
        margin-top: 10px;
    
    }
    .testimon-1stpng {
        width: 3rem;
        margin-top: 50px;
        margin-left: 10px;
    }
    .review1 {
        margin: 73px 20px 5px 30px;
    }
    .review1 p {
        font-size: 11px;
        margin-top: 6px;
    }


    
    /*seventh section*/

    .quote-header{
        background-size: cover;
    } 
    .quote-content h2 {
        padding-top: 10px;
        font-size: 22px;
        font-weight: 400;
        line-height: 1;
    }
    .quote-content span {
        font-size: 18px;
    }
    .quote-png1 {
        margin-top: -79px;
        width: 4rem;
        margin-right: 13px;
        height: fit-content;
    }
    .startbtn-quote {
        padding: 1px 5px;
        margin: 12px;
        font-size: 15px;
        font-weight: 200;
        color: white;
    }
    

    /*eigth section */
    
    .client-logos {
        margin: 0px;
        padding: 0px;
    }
    .client-logos img {
        width: 12%;
        margin: 10px;
    }

    .footer-header{
        background-size: auto;
        background-repeat: repeat;
    }
    .footer-content {
        padding: 0px;
        text-align: center;
        display: inline;
    }
    .footer-logo {
        width: 100%;
        margin-top: 0px;
    }
    .footer-logo-png {
        width: 12rem;
        margin-bottom: 0px;
    }
    .footer-logo p {
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        padding: 0px 0px;
        margin-left: 0px;
    }
    
    p {
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 20px;
    }
    
    .add-content {
        font-size: 16px;
        font-weight: 600;
    }

    ol, ul {
        padding-left: 0rem;
    }
    
    .footer-links {
        width: 100%;
        display: inline;
    }
    .footer-quicklink {
        margin-top: 0px;
        padding: 0px;
        width: 25%;
        margin-left: 10px;
    }
    .footer-ql-ul {
        font-size: 27px;
        font-weight: 600;
        width: 22rem;
    }
    .footer-ql-ul li {
        font-size: 18px;
        font-weight: 500;
    }
    
    
    
    .footer-service {
        margin-top: 0px;
        padding: 0px;
        width: 30%;
        margin-left: 10px;
    }
    .footer-service-ul {
        font-size: 25px;
        font-weight: 600;
        width: 16rem;
    }
    .footer-service-ul li {
        font-size: 18px;
        font-weight: 500;
    }
    
    
    
    .footer-contact1 {
        width: 100%;
        margin-top: 0px;
        padding: 0px;
        margin-left: 10px;
    }
    .con-head {
        font-size: 25px;
        font-weight: 600;
        width: 20rem;
    }
    .info-footer .InfoBoxfooter div p {
        font-size: 17px;
        font-weight: 500;
        margin-left: 0px;
    }
    .info-footer .InfoBoxfooter div a {
        font-size: 18px;
        font-weight: 500;
        margin-left: 0px;
    }
    .info-footer .InfoBoxfooter div span {
        height: 35px;
        margin-left: -8px;
    }

    .social {
        display: flex;
        justify-content: center;
        width: 90%;
    }
    .copyright p {
        font-size: 13px;
        color: white;
        margin-top: 20px;
        text-align: center;
    }


}


.textareahide{
    left: -20rem;
}


@media (min-width: 1440px) and (max-width: 1600px) {
    
    .text-center {
        margin-left: 6%;
    }

    .homecontent {
        height: 70vh;
    }

    .text-center {
        text-align: start;
    }

    .startbtn {
        padding: 12px 30px;
        font-size: 25px;
        margin-right: 60%;
    }

}


/* Big Screen style Here */
@media screen and  (min-width: 1650px) and (max-width: 1930px) {

    .text-center p {
        font-size: 35px;
        font-weight: 100;
    }

    .img-fluid-home {
        max-width: 100%;
        height: 350px;
    }

    .text-center {
        margin-top: 60px;
        padding-top: 8%;
        padding-left: 15%;
    }

    .startbtn {
        padding: 12px 30px;
        font-size: 25px;
        margin-right: 60%;
    }

    .img-fluid-home {
        max-width: 100%;
        height: 80%;
        margin-top: 6%;
    }

    

    .word-data {
        margin-top: 22px;
        margin-left: 10px;
        color: white;
    }

    .carousel-item .three-img {
        width: 29%;
        margin-top: 30px;
        position: relative;
        height: 254px;
        bottom: 10px;  
        left: 34%;
}

.testimon-header {
    min-height: 57vh;
}

}