.insta-main p {
    color: rgb(218, 19, 19);
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 3%;
    margin-bottom: 3%;
    font-size: 35px;
    font-weight: 600;
}

.insta-main {
    position: relative;
    margin-top: 170px;
}
  
.card:hover {
    transform: scale(1.1); /* Increase the size of the card on hover */
    transition: 0.8s;
}

.card-body:hover {
    background-color: rgb(41, 195, 41);
    margin-top: 15px;
}

.second-main {
    background-image: url('https://dm0qx8t0i9gc9.cloudfront.net/watermarks/image/rDtN98Qoishumwih/abstract-blue-light-background_SvZPryOnfl_SB_PM.jpg'); /* Replace 'your-background-image-url.jpg' with the URL of your desired background image */
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the background image */
    padding: 20px; /* Add some padding to the container */
    width: 100%;
  }

.insta-card-title {
    font-size: 16px;
    /* margin-top: 15px; */
}

.card {
    border: none;
    /* width: 300px;
    height: 300px; */
    overflow: hidden;
    /* margin: 50px auto; */
    border-radius: 30px 30px 30px 30px;
}

.second-main2 {
    margin: 200px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.card-body {
    background-color: rgb(200, 17, 17);
    height: 60px;
    text-align: center;
    color: white;
}

.card img {
    /* width: 100%; */
    /* height: 100%; */
    object-fit: cover;
    /* object-position: top; */
    transition: all 1s ease;
    /* border-radius: 50px; */
}


h5 {
    color: white;
}

@media (min-width: 320px) and (max-width: 567px) {

    .second-main2 {
        margin: 0px;
    }

    .insta-main p {
        font-size: 17px;
    }

    .insta-main {
        margin-top: 0px;
    }
}


@media (min-width: 568px) and (max-width: 991px) {

    .second-main2 {
        margin: 0px;
    }

    .insta-main p {
        font-size: 24px;
    }

    .insta-main {
        margin-top: 0px;
    }

    .insta-card-title {
        font-size: 16px;
    }

    .card img {
        object-fit: cover;
        transition: all 1s ease;
    }

    .main p {
        font-size: 30px;
    }
}



@media (min-width: 991px) and (max-width: 1200px) {

    .second-main2 {
        margin: 80px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}