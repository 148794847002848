
@media (min-width: 320px) and (max-width: 340px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 41px;
        bottom: 6px;
    }

    
}

@media (min-width: 340px) and (max-width: 360px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 45px;
        bottom: 6px;
    }
}

@media (min-width: 360px) and (max-width: 390px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 52px;
        bottom: 6px;
    }

}

@media (min-width: 390px) and (max-width: 420px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 58px;
        bottom: 6px;
    }

}

@media (min-width: 420px) and (max-width: 440px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 62px;
        bottom: 6px;
    }

}


@media (min-width: 440px) and (max-width: 464px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 66px;
        bottom: 6px;
    }

}

@media (min-width: 464px) and (max-width: 477px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 67px;
        bottom: 6px;
    }

}

@media (min-width: 464px) and (max-width: 991px) {
    
    .header-content {
        display: flex;
        justify-content: space-between;
        gap: 90%;
    }
}

@media (min-width: 320px) and (max-width: 464px) {
    
    .header-content {
        display: flex;
        justify-content: space-between;
        gap: 85%;
    }
}

@media (min-width: 476px) and (max-width: 565px) {
    .carousel-item .three-img {
        width: 56%;
        position: relative;
        margin: 20px;
        height: 192px;
        left: 71px;
        bottom: 6px;
    }

}


@media (min-width: 565px) and (max-width: 767px) {
    .carousel-item .three-img {
        width: 45%;
        margin-top: 30px;
        position: relative;
        bottom: 9px;
        height: 254px;
        left: 25%;
    }
}


@media (min-width: 768px) and (max-width: 990px) {
    .carousel-item .three-img {
        width: 45%;
        margin-top: 30px;
        position: relative;
        bottom: 15px;
        height: 192px;
        left: 25%;
    }

}

@media (min-width: 991px) and (max-width: 1198px) {
    .carousel-item .three-img {
        width: 45%;
        margin-top: 30px;
        position: relative;
        bottom: 9px;
        height: 254px;
        left: 25%;
    }
}

@media (min-width: 1199px) and (max-width: 1440px) {
    .carousel-item .three-img {
                width: 29%;
                margin-top: 30px;
                position: relative;
                height: 254px;
                bottom: 10px;  
                left: 34%;
    }
}

@media (min-width: 1441px) and (max-width: 1800px) {
    .carousel-item .three-img {
                width: 29%;
                margin-top: 30px;
                position: relative;
                height: 254px;
                bottom: 10px;  
                left: 34%;
    }
}

@media screen and (min-width: 1301px) {
/* body{
    background-color: #02182D;
} */

.nav-links {
    width: 68%;
    font-size: 24px;
    text-align: center;
    margin-bottom: 0px;
}
}