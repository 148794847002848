.about-img-container {
    margin: 0px;
    text-align: center;
}

.about-center {
    max-width: 100%;
    display: flex;
    justify-content: center;
}

.img-fluid {
    margin-top: 10%;
}

.about-content2 button:hover {
    transform: translate(0, -15%) scale(1);
    transition: all 0.4s ease;
}

.about-background {
    max-width: 99%;
}

.head-container {
    margin-top: 6%;
    margin-bottom: 0%;
    background-image: url('C:\Users\prasa\OneDrive\Documents\react-phoenix\phoenix\Home\public\Imagefile\2nd_secBG.jpg');
    /* background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    padding: 30px; /* Adjust padding as needed */
    padding-bottom: 6%;
    height: 92vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.about-content2 {
    margin-top: 10%;
    text-align: left;
}

.about-content2 h1 {
    text-align: left;
    font-size: 50px;
    color: #737373;
}

.about-content2 h3 {
    color: #AE0002;
    font-size: 33px;
    font-weight: 700;
}

.about-content2 p {
    color: black;
    font-size: 24px;
    font-weight: 600;
}

.about-content2 button {
    background-color: #75090b;
    color: white;
    padding: 10px;
    border-radius: 0px;
    margin-top: 15px;
    margin-left: 0px;
}


@media (min-width: 320px) and (max-width: 476px) {
    .about-background {
        max-width: 99%;
    }

    .head-container {
        height: auto;
        padding: 0px;
    }

    .about-content2 h1{
        text-align: center;
        font-size: 38px;
    }

    .about-content2 h3 {
        text-align: center;
        font-size: 17px;
    }

    .about-content2 p {
        text-align: center;
        font-size: 15px;
    }

    .about-content2 {
        text-align: center;
        padding: 10px;
    }
}


@media (min-width: 476px) and (max-width: 768px) {
    .about-content2 h1{
        text-align: center;
    }

    .head-container {
        margin-top: 3%;
    }

    .about-content2 h3 {
        text-align: center;
        font-size: 24px;
    }

    .about-content2 p {
        text-align: center;
        font-size: 20px;
    }

    .about-content2 {
        text-align: center;
    }
}


@media (min-width: 769px) and (max-width: 991px) {
    .about-content2 {
        text-align: center;
    }

    .head-container {
        margin-top: 2%;
        margin-bottom: 0px;
    }

    .img-fluid {
        /* width: 100%; */
        height: 300px;
        margin-top: 5%;
    }

    .about-img-container {
        margin: 0px;
        text-align: center;
        margin-top: 0%;
    }

    .about-content2 h3 {
        color: #AE0002;
        font-size: 34px;
        font-weight: 700;
        text-align: center;
    }

    .about-content2 h1 {
        font-size: 30px;
        color: #737373;
        text-align: center;
    }

    .about-content2 p {
        color: black;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }

    .about-content2 button {
        background-color: #75090b;
        color: white;
        padding: 10px;
        border-radius: 0px;
        margin-top: 15px;
        /* margin-left: 0px; */
        /* text-align: center; */
    }
}


@media (min-width: 991px) and (max-width: 1025px) {
      
    .head-container {
        margin-top: 6%;
        margin-bottom: 0px;
    }

    .img-fluid-home {
        max-width: 100%;
        height: 350px;
        padding: 20px;
    }

    .about-background {
        max-width: 100%;
    }
}


@media (min-width: 1025px) and (max-width: 1440px) {
    .head-container {
        margin-top: 5%;
        margin-bottom: 0px;
    }

    .img-fluid-home {
        max-width: 100%;
        height: 350px;
    }
}


@media screen and  (min-width: 1650px) and (max-width: 1930px) {
    
    .head-container {
        margin-top: 5%;
        margin-bottom: 0%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 30px;
        height: 70vh;
    }

    .about-content2 {
        margin-top: 17%;
        text-align: left;
    }
}