.social-header-bg {
    min-height: 70vh;
    background-image:  url('C:\Users\prasa\OneDrive\Desktop\Phoenix Home\Home\public\Social_media\Page3BG.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden; 
}

.image-container {
    position: relative;
    margin: 0px;
}

.main-contents {
    position: relative;
    top: 0px;
}

.middleimg-social {
    border: none;
}

.middleimg-social img {
    border-radius: 15px;
}

.video-container {
    position: relative;
    overflow: hidden;
}

/* .video-container:hover video {
    width: 100%;
    height: 300px;
} */

.videos {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    transition: width 0s, height 0s;
}

.img-fluid {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.img-fluid:hover {
    transform:  scale(1.1);
    z-index: 1;
}

.middleimg img {
    border-radius: 15px;
}

.down-content p {
    font-size: 20px;
    color: gray;
    font-weight: 600;
}

.hover-underline {
    text-decoration: none; /* Remove default underline */
    position: relative;
}

.hover-underline::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px; /* Adjust the distance from text to underline */
    width: 100%;
    height: 2px;
    background-color: #000; /* Change color as needed */
    transition: bottom 0.3s ease-in-out;
    transform: scaleX(0);
    transform-origin: bottom right;
}

.hover-underline:hover::after {
    bottom: 0;
    transform: scaleX(1);
    transform-origin: bottom left;
}


.down-content h3 {
    color: #891212;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
}

.pagein-content h3 {
    color: #891212;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
}

.pagein-content h3 {
    position: relative;
}

.pagein-content h3::after {
    content: ''; 
    position: absolute; 
    left: 0; 
    right: 0; /* Ensures the underline spans the width of the <h3> */
    bottom: 0; 
    margin: auto; /* Centers the underline horizontally */
    width: 0%; /* Adjusts width based on content */
    height: 5px; 
    background-color: #891212; 
    transition: width 1s; 
    z-index: -1; 
    border-radius: 15px;
    bottom: -10px;
}

.pagein-content h3:hover::after {
    width: 20%; /* Ensure the underline spans the full width on hover */
    bottom: -10px;
}


.down-content h3 {
    position: relative;
}

.down-content h3::after {
    content: ''; 
    position: absolute; 
    left: 0; 
    right: 0; /* Ensures the underline spans the width of the <h3> */
    bottom: 0; 
    margin: auto; /* Centers the underline horizontally */
    width: 0%; /* Adjusts width based on content */
    height: 5px; 
    background-color: #891212; 
    transition: width 1s; 
    z-index: -1; 
    border-radius: 15px;
    bottom: -10px;
}

.down-content h3:hover::after {
    width: 20%; /* Ensure the underline spans the full width on hover */
    bottom: -10px;
}

.pagein-content p {
    font-size: 24px;
    color: #666F74;
    text-align: left;
    align-items: justify;
}

.down-content p {
    font-size: 18px;
    font-weight: 600;
    color: #666F74;
    text-align: left;
    align-items: justify;
}

.container-social {
   margin-left: 8%;
   margin-right: 8%;
}

.pagein-content {
    text-align: center;
}

.down-content {
    text-align: center;
}

.headings {
    font-size: 17px;
    color: #891212;
    font-weight: 700;
    text-align: center;
}

.headings:hover {
    background-color: #891212;
    color: white;
    border-radius: 8px;
    padding-left: 10px;
    transition: 0.5s;
}

.headings.active {
    background-color: #891212; /* Red background for active */
    color: white; /* Optional: Change text color */
    border-color: #891212;
    border-radius: 8px;
    padding-left: 10px;
    transition: 0.5s;
}

.line {
    border: 1.5px solid yellow;
    margin-top: 5px;
    width: 42%;
    margin-bottom: 30px;
}

.text-contents {
    margin-top: 8%;
    margin-left: 0%;
}

.text-contents p{
    text-align: justify;
    font-size: 24px;
    letter-spacing: 0px;
    align-items: justify;
    margin-right: 30%;
    animation: headercontent 2s ease-in-out;
  }
  @keyframes headercontent{
      0%{
          opacity: 0;        
          transform: translatex(50%);
      }
  }

.text-contents h3{
    animation: headercontent 2s ease-in-out;
    color: white;
    font-size: 38px;
  }
  @keyframes headercontent{
      0%{
          opacity: 0;        
          transform: translatex(50%);
      }
  }

@media screen and  (min-width: 1650px) and (max-width: 1930px) {

  .social-header-bg {
    min-height: 60vh;
    /* background-image: url(http://localhost:3000/static/media/Page3BG.565634d….jpg); */
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}

.text-contents {
    margin-top: 13%;
    margin-left: 0%;
}

}

  @media screen and (min-width: 1300px) and (max-width:1550px){

    .social-header-bg {
        margin-top: 95px;
    }
}


@media screen and (min-width: 1200px) and (max-width:1300px){

    .digital-header-bg img{
        min-height: 61vh;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
 
    .digital-header-bg img{
        min-height: 54vh;
    }

    .social-header-bg {
        margin-top: 9%;
    }

    .text-contents p {
        text-align: left;
        font-size: 24px;
        letter-spacing: 0px;
        align-items: justify;
        margin-right: 6%;
        animation: headercontent 2s ease-in-out;
      }
      @keyframes headercontent{
          0%{
              opacity: 0;        
              transform: translatex(50%);
          }
      }

      .text-contents h3{
        animation: headercontent 2s ease-in-out;
        color: white;
        font-size: 30px;
      }
      @keyframes headercontent{
          0%{
              opacity: 0;        
              transform: translatex(50%);
          }
      }

      .line {
        border: 1.5px solid yellow;
        margin-top: 5px;
        width: 46%;
        margin-bottom: 30px;
    }

    .container-social {
        max-width: 98%;
        margin: auto;
    }

    .container-social h3 {
        font-size: 30px;
        font-weight: 700;
    }

    .container-social p {
        font-size: 17px;
    }

    .headings p {
        font-size: 12px;
        font-weight: 600;
    }

    .pagein-content p {
        color: #666F74;
        font-size: 18px;
        text-align: justify;
    }
}

@media screen and  (min-width: 768px) and (max-width: 991px) {

    .digital-header-bg img{
        background-size: cover;
    }

    .digital-header-bg img{
        min-height: 41vh;
    }

    .text-contents p {
        text-align: left;
        font-size: 20px;
        letter-spacing: 0px;
        align-items: justify;
        /* margin-right: 6%; */
        animation: headercontent 2s ease-in-out;
      }
      @keyframes headercontent{
          0%{
              opacity: 0;        
              transform: translatex(50%);
          }
      }

      .text-contents h3{
        animation: headercontent 2s ease-in-out;
        color: white;
        font-size: 30px;
      }
      @keyframes headercontent{
          0%{
              opacity: 0;        
              transform: translatex(50%);
          }
      }

      .line {
        border: 1.5px solid yellow;
        margin-top: 5px;
        width: 35%;
        margin-bottom: 30px;
    }

    .container-social {
        max-width: 86%;
        margin: auto;
    }

    .container-social h3 {
        font-size: 30px;
        font-weight: 700;
    }

    .container-social p {
        font-size: 17px;
    }

    .headings p {
        font-size: 20px;
        font-weight: 600;
        border: 1px solid #891212;
        border-radius: 15px;
    }

    .pagein-content p {
        color: #666F74;
        font-size: 18px;
        text-align: justify;
    }
}

@media (min-width: 481px) and (max-width: 767px){

    .digital-header-bg img{
        background-size: cover;
    }

    .headings p {
        font-size: 20px;
        font-weight: 600;
        border: 1px solid #891212;
        border-radius: 15px;
    }

}

@media (min-width: 425px) and (max-width: 481px){

    .text-contents p {
        text-align: justify;
        font-size: 20px;
        letter-spacing: 0px;
        align-items: justify;
        margin-right: 40%;
        animation: headercontent 2s ease-in-out;
      }
      @keyframes headercontent{
          0%{
              opacity: 0;        
              transform: translatex(50%);
          }
      }

      .text-contents h3{
        animation: headercontent 2s ease-in-out;
        color: white;
        font-size: 28px;
      }
      @keyframes headercontent{
          0%{
              opacity: 0;        
              transform: translatex(50%);
          }
      }

      .line {
        border: 1.5px solid yellow;
        margin-top: 5px;
        width: 78%;
        margin-bottom: 30px;
    }

    .container-social {
        max-width: 86%;
        margin: auto;
    }

    .container-social h3 {
        font-size: 15px;
        font-weight: 700;
    }

    .container-social p {
        font-size: 16px;
    }

    .headings p {
        font-size: 20px;
        font-weight: 600;
        border: 1px solid #891212;
        border-radius: 15px;
    }
}

@media (min-width: 320px) and (max-width: 424px){

    .text-contents p {
        text-align: left;
        font-size: 24px;
        letter-spacing: 0px;
        align-items: left;
        margin-right: 6%;
        animation: headercontent 2s ease-in-out;
      }
      @keyframes headercontent{
          0%{
              opacity: 0;        
              transform: translatex(50%);
          }
      }

      .text-contents h3{
        animation: headercontent 2s ease-in-out;
        color: white;
        font-size: 20px;
      }
      @keyframes headercontent{
          0%{
              opacity: 0;        
              transform: translatex(50%);
          }
      }

      .line {
        border: 1.5px solid yellow;
        margin-top: 5px;
        width: 82%;
        margin-bottom: 30px;
    }

    .container-social {
        max-width: 86%;
        margin: auto;
    }

    .container-social h3 {
        font-size: 16px;
        font-weight: 700;
    }

    .container-social p {
        font-size: 17px;
    }

    .headings p {
        font-size: 20px;
        font-weight: 600;
        border: 1px solid #891212;
        border-radius: 15px;
    }

    .pagein-content p {
        color: #666F74;
        font-size: 18px;
        text-align: justify;
    }

}


