.containerpart1 {
    max-width: 99%;
}

.containerpart3 {
    max-width: 99%;
}

/* CSS to position the rocket image within belowsecondcontent */
.belowsecondcontent {
    position: relative; /* Ensure the container is positioned */
}

/* Adjust the z-index to ensure the rocket image is above the content */
.belowsecondcontent {
    z-index: 1; /* Increase the z-index to ensure the content is below the rocket image */
}


.firstcontent {
    margin-bottom: 8%;
}

.downside-content {
    position: relative;
    right: 80px;
}

.left-content-search h1 {
    text-align: start;
}

.paragraph {
    margin-top: 25px;
}

.paragraph h3 {
    color: #FF0000;
    font-size: 24px;
}

.second-below-para {
    margin-right: 20%;
}

.second-below-para h3 {
    color: white;
}

.galaxy {
    margin-left: 10%;
}

.galaxy img {
    position: relative;
    bottom: 10px;
}

.link-building {
    margin-bottom: 2%;
}

.belowsecondcontent h3 {
    margin-top: 5%;
    margin-left: 3%;
}

.text-image-search {
    display: flex;
    /* justify-content: end; */
    text-align: end;
    margin-top: 40px;
}

.container {
    flex-direction: row;
    border: 2px solid rgb(236, 234, 234); /* Increase border width for thicker edges */
    border-radius: 20px;
    padding: 2%;
    margin-bottom: 5%;
    /* box-shadow: 2px 2px 10px 2px #908f8f; */
    background-color: #FFFFFF;
    font-size: 18px;
    margin-top: 3%;
}

.container-upside {
    flex-direction: row;
    border: 2px solid rgb(236, 234, 234); /* Increase border width for thicker edges */
    border-radius: 20px;
    padding: 2%;
    margin-bottom: 5%;
    /* box-shadow: 2px 2px 10px 2px #908f8f; */
    background-color: #FFFFFF;
    font-size: 18px;
    margin-top: 3%;
}

.container-downside {
    flex-direction: row;
    border: 2px solid rgb(236, 234, 234); /* Increase border width for thicker edges */
    border-radius: 20px;
    padding: 2%;
    margin-bottom: 1%;
    /* box-shadow: 2px 2px 10px 2px #908f8f; */
    background-color: #FFFFFF;
    font-size: 18px;
    margin-top: 3%;
}

.container-second {
    flex-direction: row;
    border: 2px solid rgb(236, 234, 234); /* Increase border width for thicker edges */
    border-radius: 5px;
    padding: 2%;
    margin-bottom: 5%;
    background-color: #FFFFFF;
    font-size: 18px;
}

.below-content {
    background-color: #910406;
    margin-bottom: 22%;
    max-height: 920px;
    max-width: 100%;
}

.container-second1 {
    flex-direction: row;
    border: 2px solid rgb(236, 234, 234); /* Increase border width for thicker edges */
    border-radius: 5px;
    padding: 2%;
    margin-bottom: 5%;
    background-color: #FFFFFF;
    font-size: 18px;
}

.content p {
    font-size: 18px;
}

.rocket img {
    position: relative;
    bottom: 100px;
}

.rocket {
    text-align: center;
}

.belowfirstcontent h3 {
    color: white;
    font-size: 18px;
}

.belowsecondcontent {
    margin-left: 10%;
}

.belowfirstcontent {
    margin-left: 10%;
    margin-top: 5%;
}

.container-first {
    flex-direction: row;
    border: 2px solid rgb(236, 234, 234); /* Increase border width for thicker edges */
    border-radius: 5px;
    padding: 2%;
    margin-bottom: 5%;
    /* box-shadow: 2px 2px 10px 2px #908f8f; */
    background-color: #FFFFFF;
    font-size: 18px;
    margin-top: 3%;
    width: 90%;
}

.belowsecondcontent h3 {
    color: white;
    font-size: 26px;
}

.image {
    flex: 1;
    text-align: center;
}

/* .image img {
    max-width: 100%;
    height: auto;
} */

.content {
    flex: 1;
    padding-left: 20px; /* Adjust spacing between image and content */
}

h2 {
    font-size: 24px;
    margin-top: 0;
    color: #AE0002;
}

.second-paragraph {
    font-size: 22px;
    text-align: justify;
    font-weight: 400;
    color: #454444;
    margin-left: 13%;
    margin-right: 13%;
    margin-top: 2%;
    margin-bottom: 2%;
    /* font-family: "Times New Roman", Times, serif !important; */
}

.left-content-search {
    margin-left: 15%;
    margin-right: 10%;
    text-align: justify;
}

.image-container {
    position: relative;
    margin: 15px;
}

.image-container img {
    z-index: 1; /* Adjust the z-index as needed */
}


.social-header-bg-paid {
    min-height: 60vh;
    background-image:  url('C:\Users\prasa\OneDrive\Desktop\Phoenix Home\Home\public\Imagefile\2Bg 1.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden; 
    margin: 0px !important;
}


.second-main {
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the background image */
    padding: 20px; /* Add some padding to the container */
    width: 100%;
    background-image: none;
  }

.icons {
    color: #AE0002;
}

/* Add this style to adjust the card height */
.card-service {
    height: 200px; /* Adjust the height as needed */
}

.icon {
    margin-top: 20px;
    margin-bottom: 20px !important;
    margin-left: 0px;
}

.card-service p {
    font-size: 16px;
}

/* Add this style to adjust the icon size */
.icon svg {
    width: 80px; /* Adjust the width of the SVG icon */
    height: auto; /* Let the height adjust automatically */
}

.left-content-search h4 {
    text-align: justify;
}

.line-service {
    border: 1.5px solid yellow;
    margin-top: 5px;
    width: 67%;
    margin-bottom: 30px;
}

.li {
    font-size: 18px;
    font-weight: 700;
    color: #4D4D4D;
    list-style: disc !important;
}

ul .centered-list {
    text-align: center;
}

.centered-list li {
    margin-bottom: 10px; /* Adjust margin as needed */
}


.image-container-paid {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
}


.card-service {
    border: 2px solid lightgrey;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
}

.container-paid {
    margin-top: 4%;
}

@media (min-width: 320px) and (max-width: 464px) {
    .image {
        display: flex;
        justify-content: center;
    }

    .galaxy img {
        height: 200px;
        width: 200px;
    }   

    .rocket {
        text-align: center;
    }

    .container-first {
        margin-left: 3%;
        margin-right: 5%;
    }

    .image-screw img {
        height: 100px;
        width: 100px;
    }

    .image-lens img {
        height: 100px;
        width: 100px;
    }

    .image-tick img {
        height: 100px;
        width: 100px;
    }

    .left-content-search {
        margin-left: 5%;
        margin-right: 5%;
        text-align: justify;
        margin-top: 5%;
    }

    .left-content-search h1 {
        font-size: 20px;
    }

    .text-image-search img {
        height: 400px;
        width: 350px;
        margin-left: 25%;
    }
    
    .image img {
        height: 300px;
        width: 300px;
        margin-bottom: 10%;
    }

    .second-paragraph {
        font-size: 18px;
        text-align: justify;
        margin-top: 5%;
    }

    .text-image-service {
        display: flex;
        justify-content: center;
    }
    
    .text-image-service img {
        max-width: 100%; /* Ensure the image doesn't exceed its container */
        margin-left: 15%;
    }

    .line-service {
        margin-left: 0px;
    }
    
    .image img {
       height: 250px;
       width: 250px;
    }

    .second-paragraph {
        font-size: 14px ;
        text-align: justify;
        margin-top: 5%;
        margin-left: 7%;
        margin-right: 7%;
    }

    .container-upside {
        border: 2px solid rgb(236, 234, 234);
        border-radius: 20px;
        padding: 2%;
        margin-bottom: 5%;
        background-color: #FFFFFF;
        font-size: 15px;
        margin-top: 3%;
    }

    .container-downside {
        flex-direction: row;
        border: 2px solid rgb(236, 234, 234);
        border-radius: 20px;
        padding: 2%;
        margin-bottom: 1%;
        background-color: #FFFFFF;
        font-size: 15px;
        margin-top: 3%;
    }

    .text-image-service {
        display: flex;
        justify-content: center;
    }
    
    .text-image-service img {
        max-width: 100%; /* Ensure the image doesn't exceed its container */
        margin-left: 20%;
        margin-top: 0px;
    }

    .rocket {
        position: relative;
        bottom: 0px;
        margin-bottom: 5%;
    }

    .rocket img {
        height: 280px;
        width: 210px;
    }

    .image-screw {
        margin-bottom: 20px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }

    .belowsecondcontent {
        margin-left: 4%;
    }

    .container-second {
        margin-right: 6%;
        margin-left: 5%;
    }

    .image-lens {
        margin-bottom: 20px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }

    .image-tick {
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }

    .image-tick {
        margin-bottom: 20px;
    }


    .belowsecondcontent {
        margin-left: 2%;
    }

    .downside-content {
        position: relative;
        right: 0px;
        margin-left: 4%;
    }

    .upside-content {
        margin-left: 4%;
    }

    .text-image-search {
        display: flex;
        justify-content: center;
        text-align: end;
        margin-top: 0px;
    }
    
    .galaxy {
        text-align: center;
        margin-left: 0%;
        margin-top: 10%;
        margin-bottom: 5%;
    }

    .galaxy img {
        height: 250px;
        width: 250px;
    }

    .below-content {
        background-color: #910406;
        margin-bottom: 0%;
        max-height: 3000px;
    }

    .rocket img {
        position: relative;
        bottom: 0px;
    }

    .second-below-para {
        margin-right: 3% ;
        margin-left: 0%;
        margin-top: 5%;
    }

    .second-below-para h3 {
        color: white;
        margin-left: 5%;
    }

}

@media (min-width: 476px) and (max-width: 565px) {
    .image {
        display: flex;
        justify-content: center;
    }

    .left-content-search {
        margin: 3%;
    }

    .text-image-search img {
        height: 500px;
        width: 450px;
        text-align: center;
        margin-left: 20%;
    }

    .line-service {
        margin-left: 0px;
    }
    
    .image img {
       height: 400px;
       width: 450px;
    }

    .second-paragraph {
        font-size: 18px ;
        text-align: justify;
        margin-top: 5% ;
    }

    .text-image-service {
        display: flex;
        justify-content: center;
    }
    
    .text-image-service img {
        max-width: 100%; /* Ensure the image doesn't exceed its container */
        margin-left: 20%;
        margin-top: 0px;
    }

    .rocket {
        position: relative;
        bottom: 0px;
    }

    .rocket img {
        height: 450px;
        width: 300px;
    }

    .image-screw {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .belowsecondcontent {
        margin-left: 4%;
    }

    .container-second {
        margin-right: 4%;
    }

    .image-lens {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .image-tick {
        margin-top: 10px;
    }

    .image-tick {
        margin-bottom: 20px;
    }
    
    .image img {
        max-width: 100%; /* Ensure the image doesn't exceed its container */
    }

    .belowsecondcontent {
        margin-left: 2%;
    }

    .downside-content {
        position: relative;
        right: 0px;
        margin-left: 4%;
    }

    .upside-content {
        margin-left: 4%;
    }

    .text-image-search {
        display: flex;
        justify-content: center;
        text-align: end;
        margin-top: 0px;
    }
    
    .galaxy {
        text-align: center;
        margin-left: 0%;
        margin-top: 10%;
    }

    .galaxy img {
        height: 350px;
        width: 350px;
    }

    .below-content {
        background-color: #910406;
        margin-bottom: 15%;
        max-height: 3000px;
    }

    .rocket img {
        position: relative;
        bottom: 0px;
    }

    .second-below-para {
        margin-right: 3% ;
        margin-left: 7%;
        margin-top: 5%;
    }
}

@media (min-width: 566px) and (max-width: 767px) {
    .image {
        display: flex;
        justify-content: center;
    }
    
    .image img {
        max-width: 100%; /* Ensure the image doesn't exceed its container */
        margin-bottom: 5%;
    }

    .second-paragraph {
        font-size: 18px;
        text-align: justify;
        margin-top: 5%;
    }

    .content p {
        font-size: 18px;
    }

    .line-service {
        margin-left: 0px;
    }
    
    .image img {
       height: 400px;
       width: 450px;
    }

    .second-paragraph {
        font-size: 18px ;
        text-align: justify;
        margin-top: 5% ;
    }

    .text-image-service {
        display: flex;
        justify-content: center;
    }
    
    .text-image-service img {
        max-width: 100%; /* Ensure the image doesn't exceed its container */
        margin-left: 20%;
        margin-top: 0px;
    }

    .rocket {
        position: relative;
        bottom: 0px;
    }

    .rocket img {
        height: 450px;
        width: 300px;
    }

    .image-screw {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .belowsecondcontent {
        margin-left: 4%;
    }

    .container-second {
        margin-right: 4%;
    }

    .image-lens {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .image-tick {
        margin-top: 10px;
    }

    .image-tick {
        margin-bottom: 20px;
    }
    
    .image img {
        max-width: 100%; /* Ensure the image doesn't exceed its container */
    }

    .belowsecondcontent {
        margin-left: 2%;
    }

    .downside-content {
        position: relative;
        right: 0px;
        margin-left: 4%;
    }

    .upside-content {
        margin-left: 4%;
    }

    .text-image-search {
        display: flex;
        justify-content: center;
        text-align: end;
        margin-top: 0px;
    }

    .text-image-search img {
        height: 500px;
        width: 450px;
        margin-left: 20%;
    }
    
    .galaxy {
        text-align: center;
        margin-left: 0%;
        margin-top: 10%;
    }

    .galaxy img {
        height: 350px;
        width: 350px;
    }

    .below-content {
        background-color: #910406;
        margin-bottom: 15%;
        max-height: 3000px;
    }

    .rocket img {
        position: relative;
        bottom: 0px;
    }

    .second-below-para {
        margin-right: 3% ;
        margin-left: 7%;
        margin-top: 5%;
    }

}

@media (min-width: 768px) and (max-width: 990px) {
    .image {
        display: flex;
        justify-content: center;
    }

    .image img {
        height: 400px;
        width: 400px;
    }

    .rocket img {
        height: 360px;
        width: 300px;
    }

    .rocket {
        text-align: center;
    }

    .text-image-search img {
        height: 500px;
        width: 450px;
    }

    .galaxy img {
        height: 300px;
        width: 300px;
    }

    .below-content {
        background-color: #910406;
        margin-bottom: 15%;
        max-width: auto;
    }

    .rocket {
        position: relative;
        bottom: 0px;
    }

    .image-screw {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .belowsecondcontent {
        margin-left: 4%;
    }

    .container-second {
        margin-right: 4%;
        width: 90%;
    }

    .image-lens {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .image-tick {
        margin-top: 10px;
    }

    .image-tick {
        margin-bottom: 20px;
    }

    .belowsecondcontent {
        margin-left: 5%;
        width: 95%;
    }

    .downside-content {
        position: relative;
        right: 0px ;
        margin-left: 4%;
    }

    .upside-content {
        margin-left: 4%;
    }

    .text-image-search {
        display: flex;
        justify-content: center;
        text-align: end;
        margin-top: 0px;
    }
    
    .galaxy {
        text-align: center;
        margin-left: 0% ;
        margin-top: 10%;
    }

    .below-content {
        background-color: #910406;
        margin-bottom: 0%;
        max-height: 3000px ;
    }

    .rocket img {
        position: relative;
        bottom: 0px ;
    }

    .second-below-para {
        margin-right: 3% ;
        margin-left: 7%;
        margin-top: 5%;
    }

    .second-paragraph {
        font-size: 18px;
    }
}

@media (min-width: 991px) and (max-width: 1198px) {
    .image {
        display: flex;
        justify-content: center;
    }

    .below-content {
        background-color: #910406;
        margin-bottom: 15%;
        max-width: auto;
    }

    .text-image-search img {
        height: 400px;
        width: 350px;
    }

    .rocket {
        position: relative;
        bottom: 0px;
    }

    .galaxy img {
        height: 250px;
        width: 250px;
    }

    .image-screw {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .image-screw img {
        height: 80px;
        width: 80px;
    }

    .image-lens img {
        height: 80px;
        width: 80px;
    }

    .image-tick img {
        height: 80px;
        width: 80px;
    }

    .belowsecondcontent {
        margin-left: 4%;
    }

    .container-second {
        margin-right: 4%;
        width: 90%;
    }

    .image-lens {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .image-tick {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .image img {
        max-width: 100%; /* Ensure the image doesn't exceed its container */
    }

    .belowsecondcontent {
        margin-left: 5%;
        width: 95%;
    }

    .downside-content {
        position: relative;
        right: 0px ;
        margin-left: 4%;
    }

    .upside-content {
        margin-left: 4%;
    }

    .text-image-search {
        display: flex;
        justify-content: center;
        text-align: end;
        margin-top: 0px;
    }
    
    .galaxy {
        text-align: center;
        margin-left: 0% ;
        margin-top: 10%;
    }

    .below-content {
        background-color: #910406;
        margin-bottom: 9%;
        max-height: 3000px ;
    }

    .rocket img {
        position: relative;
        bottom: 65px;
        right: 30px;
    }

    .second-below-para {
        margin-right: 3% ;
        margin-left: 7%;
        margin-top: 5%;
    }
}

@media (min-width: 1199px) and (max-width: 1440px) {
    
    .image {
        display: flex;
        justify-content: center;
    }

    .below-content {
        background-color: #910406;
        margin-bottom: 15%;
        max-width: auto;
    }

    .text-image-search img {
        height: 400px;
        width: 350px;
    }

    .rocket {
        position: relative;
        bottom: 0px;
    }

    .galaxy img {
        height: 250px;
        width: 250px;
    }

    .image-screw {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .image-screw img {
        height: 80px;
        width: 80px;
    }

    .image-lens img {
        height: 80px;
        width: 80px;
    }

    .image-tick img {
        height: 80px;
        width: 80px;
    }

    .belowsecondcontent {
        margin-left: 4%;
    }

    .container-second {
        margin-right: 4%;
        width: 90%;
    }

    .image-lens {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .image-tick {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .image img {
        max-width: 100%; /* Ensure the image doesn't exceed its container */
    }

    .belowsecondcontent {
        margin-left: 5%;
        width: 95%;
    }

    .downside-content {
        position: relative;
        right: 0px ;
        margin-left: 4%;
    }

    .upside-content {
        margin-left: 4%;
    }

    .text-image-search {
        display: flex;
        justify-content: center;
        text-align: end;
        margin-top: 0px;
    }
    
    .galaxy {
        text-align: center;
        margin-left: 0% ;
        margin-top: 10%;
    }

    .below-content {
        background-color: #910406;
        margin-bottom: 15%;
        max-height: 3000px ;
    }

    .rocket img {
        position: relative;
        bottom: 0px ;
    }

    .second-below-para {
        margin-right: 3% ;
        margin-left: 7%;
        margin-top: 5%;
    }
}