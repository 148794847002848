.client-logos {
    display: flex;
    overflow: hidden;
  }

.owl-dots {
  display: none;
}
  
  .client-logos img {
    width: 100px; /* Adjust image width as needed */
    height: auto;
    margin-right: 20px; /* Adjust spacing between images */
    transition: margin-right 1s; /* Slide transition duration */
  }
  
  .client-logos img:last-child {
    margin-right: 0; /* Remove margin from last image */
  }
  
  .item {
    cursor: pointer;
  }


.icon {
  cursor: pointer;
}

.foot .icon:hover {
  transform: translate(0, -15%) scale(1);
  transition: all 0.4s ease;
}