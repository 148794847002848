.middleimg-pack {
    border: none;
}

.package-content-p {
    /* font-size: 24px;
    color: #666F74;
    text-align: left;
    align-items: justify; */
    font-size: 18px;
    font-weight: 600;
    color: #666F74;
    text-align: left;
}

.middleimg-pack img  {
    border-radius: 15px;
}

/* .img-fluid3 {
    height: 300px;
    width: 300px;
} */

.img-fluid3 {
    height: auto;
    width: 95%;
}

.img-fluid3:hover {
    cursor: pointer;
    transform:  scale(1.1);
    z-index: 1;
}