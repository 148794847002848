@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap'); /*sync*/

* {
    font-family: "Syne", sans-serif !important;
}


.main-content-digital {
    position: relative;
    top: 0px !important;
}

.digital-header-bg {
    background-image:  url('C:\Users\prasa\OneDrive\Desktop\Phoenix Home\Home\public\Imagefile\First Banner BG.jpg');
    background-size: cover;
    background-position: center;
    padding: 20px; /* Add padding for better spacing */
}

.second-content {
    margin-bottom: 60px;
}

.text-content p {
    font-size: 18px;
    margin-bottom: -1%;
    /* margin-left: 22.5%; */
    letter-spacing: 1px;
    text-align: left;
    animation: headercontent 2s ease-in-out;
  }
  @keyframes headercontent{
      0%{
          opacity: 0;        
          transform: translatex(50%);
      }
  }

  .text-content h2 {
    font-size: 18px;
    margin-bottom: -1%;
    /* margin-left: 22.5%; */
    letter-spacing: 1px;
    text-align: left;
    animation: headercontent 2s ease-in-out;
  }
  @keyframes headercontent{
      0%{
          opacity: 0;        
          transform: translatex(50%);
      }
  }

  .first-content img {
    font-size: 18px;
    margin-bottom: -1%;
    /* margin-left: 22.5%; */
    letter-spacing: 1px;
    text-align: left;
    animation: headerscontent 2s ease-in-out;
  }
  @keyframes headerscontent{
      0%{
          opacity: 0;        
          transform: translatex(-50%);
      }
  }

  .right-image img {
    font-size: 18px;
    margin-bottom: -1%;
    /* margin-left: 22.5%; */
    letter-spacing: 1px;
    text-align: left;
    animation: secondimage 2s ease-in-out;
  }
  @keyframes secondimage{
      0%{
          opacity: 0;        
          transform: translatex(50%);
      }
  }

  .heading h3 {
    font-size: 18px;
    margin-bottom: -1%;
    /* margin-left: 22.5%; */
    letter-spacing: 1px;
    text-align: left;
    animation: second-contents 2s ease-in-out;
  }
  @keyframes second-contents{
      0%{
          opacity: 0;        
          transform: translatex(-50%);
      }
  }

  .heading h6 {
    letter-spacing: 0px;
    animation: second-contents 2s ease-in-out;
  }
  @keyframes second-contents{
      0%{
          opacity: 0;        
          transform: translatex(-50%);
      }
  }


.first-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.second-con-img {
    height: 90%;
    width: 90%;
}

.white-icon {
    color: white;
}

.text-content p {
    color: white;
    font-size: 30px;
}

.text-content h2 {
    color: white;
    font-size: 44px;
    /* letter-spacing: 6px; */
}

.text-contents {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    /* margin-top: 10%; */
}

.text-center p {
    font-size: 24px;
    font-weight: 100 !important;
}

.container {
    border: 1px solid black;
}

.heading {
    text-align: justify;
}

.icon {
    font-size: 30px;
    margin-left: 15px;
}

.heading h3 {
    color: #c40016;
    text-align: center;
    margin-top: 13%;
    margin-bottom: 1%;
    font-size: 33px;
    font-weight: 600;
}

.heading h6 {
    font-size: 24px;
    font-weight: 500;
    display: flex;
    text-align: justify; 
    /* text-align-last: justify; */
    margin-left: 8%;
    margin-right: 8%;
    margin-top: 1%;
    display: inline-block;
    color: rgb(40, 39, 39);
    /* letter-spacing: 1px; */
}

.right-image img {
    margin-top: 24%;
}

.letter p {
    border: 1px solid white;
    box-shadow: 1px 1px 1px 1px gray;
    border-radius: 5px;
    color: #891212;
    font-size: 17px;
    font-weight: 600;
    /* font-weight: 500; */
    padding: 10px;
    text-align: center;
}

.second-content {
    max-width: 99%;
}


.full-content {
    background-color: maroon;
    width: 100%;
}

.letter:hover {
    transform: translate(2%, 2%) scale(1);
}

.box-content {
    margin: 50px;
}

@media (min-width: 991px) and (max-width: 1145px) {

    .heading p {
        margin-left: 10% !important;
    }
    
}

@media (min-width: 569px) and (max-width: 767px) {

    .text-content p {
        display: flex;
        justify-content: center;
    }

    .text-content h2 {
        display: flex;
        justify-content: center;
    }

}


@media (min-width: 320px) and (max-width: 567px) {

    .letter {
        margin: 5px;
        text-align: center;
    }

    .text-content p {
        color: white;
        font-size: 22px;
        display: flex;
        justify-content: center;
    }   

    .text-content h2 {
        color: white;
        font-size: 12px;
        display: flex;
        justify-content: center;
    }

    .box-content {
        margin: 15px;
    }

    .text-content h2 {
        font-size: 22px;
        margin-left: 0px;
    }

    .heading p {
        font-size: 24px;
        font-size: 15px ;
        margin-left: 0% ;
    }

    .heading h3 {
        font-size: 24px;
        /* margin-bottom: 20px; */
    }

    .second-con-img {
        margin-left: 0%;
    }

    .heading h6 {
    font-size: 17px;
    font-weight: 700;
    display: flex;
    margin-left: 6%;
    margin-top: 1%;
    display: inline-block;
    color: rgb(40, 39, 39);
    margin: 20px;
    }

    .right-image img {
        margin-top: 24%;
        margin-left: 8%;
        /* display: flex; */
        /* justify-content: center; */
    }
}

@media (min-width: 568px) and (max-width: 991px) {

    .heading p {
        margin-left: 10%;
    }

    .second-content {
        margin-bottom: 70px;
    }

    .heading h6 {
    font-size: 18px;
    font-weight: 700;
    display: flex;
    /* text-align: justify; */
    text-align: justify;
    /* text-align-last: justify; */
    margin-left: 8%;
    margin-right: 8%;
    margin-top: 1%;
    display: inline-block;
    color: rgb(40, 39, 39);
    letter-spacing: 0px;
    }
}

@media (min-width: 991px) and (max-width: 1250px) {
    .heading h6 {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    /* text-align: justify; */
    text-align: justify;
    /* text-align-last: justify; */
    margin-left: 5%;
    margin-right: 2%;
    margin-top: 1%;
    display: inline-block;
    color: rgb(40, 39, 39);
    letter-spacing: 0px ;
    }

    .text-content h2 {
        font-size: 28px !important;
    }
}

@media (min-width: 320px) and (max-width: 425px) {

    .right-image {
        margin-top: 0px !important;
    }

}

@media (min-width: 414px) and (max-width: 568px) {

    .right-image {
        position: relative;
        top: 35%;
        left: 20%;
        text-align: justify;
        height: 300px;
        width: 300px;
    }

    .second-con-img {
        height: 300px;
        width: 300px;
    }
}

@media (min-width: 320px) and (max-width: 568px) {

    .text-content p {
        display: flex;
        justify-content: center;
    }

    .text-content h2 {
        display: flex;
        justify-content: center;
        font-size: 21px !important;
        margin-top: 10px;
    }
}

@media (min-width: 568px) and (max-width: 991px) {
    
    .heading h3 {
        margin-top: 5%;
    }

    .text-content h2 {
        font-size: 22px !important;
    }

    .right-image {
        position: relative;
        top: 35%;
        left: 30%;
        text-align: justify;
        height: 300px;
        width: 300px;
    }

    .heading p {
        margin-left: 13% !important;
    }

    .letter {
        /* position: relative; */
        right: 25px;
        margin-bottom: 15px;
        text-align: center;
    }

    .second-con-img {
        height: 300px;
        width: 300px;
    }

}


