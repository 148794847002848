.middleimg-logo {
    border: none !important;
}

.logo-content-p {
    font-size: 18px;
    font-weight: 600;
    color: #666F74;
    text-align: left;
    align-items: justify;
}

.middleimg-logo img {
    border-radius: 15px;
}

/* .img-fluid4 {
    height: 300px;
    width: 300px;
} */

.img-fluid4 {
    height: auto;
    width: 95%;
}

.img-fluid4:hover {
    cursor: pointer;
    transform:  scale(1.1);
    z-index: 1;
}