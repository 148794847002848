.servicecontent {
    margin: 10%;
}

.servicebackground {
    background-image: url('../imagesold/3rd_secBG.png'); /* Adjusted path */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.servicehead {
    display: flex;
    justify-content: space-between;
    margin-left: 22%;
    margin-right: 15%;
    
  }
  
  .left-section,
  .center-section,
  .right-section {
    flex-basis: 30%; /* Adjust as needed */
    text-align: center; /* Center align content */
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .left-section img {
    height: 70px;
    width: 70px;
  }

  .center-section img {
    height: 70px;
    width: 70px;
  }

  .right-section img {
    height: 70px;
    width: 70px;
  }

  .left-section p {
    font-size: 25px;
    color: white;
    margin-left: 15px;
  }

  .center-section p {
    font-size: 25px;
    color: white;
    margin-left: 15px;
    margin-bottom: 10px;
  }

  .right-section p {
    font-size: 25px;
    color: white;
    margin-left: 15px;
  }


  .servicehead {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .left-section,
  .center-section,
  .right-section {
    flex-basis: calc(33.33% - 20px); /* Adjust as needed */
    text-align: center;
  }
  
  
  @media (min-width: 320px) and (max-width: 480px) {

    .servicehead {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

  }


  @media (min-width: 1024px) and (max-width: 1129px) {

    .servicehead {
      display: flex;
      justify-content: space-between;
      margin-left: 5%;
      margin-right: 5%;
  }


  }