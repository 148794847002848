.counter-numbers {
    justify-content: space-between;
    align-items: center;
    background-color: #f4f3f3;
  }

.word-data {
    margin-top: 15px;
    margin-left: 10px;
    color: white;
}
  
  .counter-number1 {
    font-size: 48px;
    font-weight: bold;
    color: white;
  }
  
  .counter-label1 {
    font-size: 26px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif !important;
  }
  
  .overtheimg {
    justify-content: space-between;
    /* background: #B80000; */
    background: #A80012;
    padding: 40px;
    /* border-radius: 14px; */
    width: 100%;
    box-shadow: 0 10px 20px 10px rgb(63 63 63 / 10%);
  } 


  @media (min-width: 320px) and (max-width: 464px) {
    .counter-label1 {
        font-size: 18px;
        font-weight: 600;
        font-family: Arial, Helvetica, sans-serif !important;
        margin-left: 10px;
    }

    .overtheimg {
        padding: 20px;
    }

    .counter-second {
        text-align: start;
    }

    .counter-number1 {
        font-size: 35px;
        font-weight: bold;
    }
  }
  
  @media (min-width: 476px) and (max-width: 565px) {
    .counter-label1 {
        font-size: 24px;
        font-weight: 600;
        font-family: Arial, Helvetica, sans-serif !important;
        margin-left: 10px;
    }

    .counter-second {
        text-align: start;
    }

    .counter-number1 {
        font-size: 35px;
        font-weight: bold;
        /* color: #AE0002; */
    }

    .experiences {
      display: flex;
      justify-content: center;
    }

    .projects {
      display: flex;
      justify-content: center;
    }

    .professional {
      display: flex;
      justify-content: center;
    }
  }
  
  
  @media (min-width: 565px) and (max-width: 767px) {
    .counter-label1 {
        font-size: 24px;
        font-weight: 600;
        font-family: Arial, Helvetica, sans-serif !important;
        margin-left: 10px;
    }

    .counter-second {
        text-align: center;
    }

    .counter-number1 {
        font-size: 35px;
        font-weight: bold;
        color: #AE0002;
    }
  }
  
  
  @media (min-width: 768px) and (max-width: 990px) {

    .counter-label1 {
        font-size: 22px;
        font-weight: 600;
        font-family: Arial, Helvetica, sans-serif !important;
        margin-left: 10px;
    }

    .counter-number1 {
        font-size: 40px;
        font-weight: bold;
        /* color: #AE0002; */
    }

    .experiences {
      display: flex;
      justify-content: center;
    }

    .projects {
      display: flex;
      justify-content: center;
    }

    .professional {
      display: flex;
      justify-content: center;
    }
  }
  
  @media (min-width: 991px) and (max-width: 1198px) {
  
      .counter-label1 {
          font-size: 18px;
          font-weight: 600;
          font-family: Arial, Helvetica, sans-serif !important;
          margin-left: 10px;
      }
  
      .counter-number1 {
          font-size: 40px;
          font-weight: bold;
          color: white;
      }

      .experiences {
        display: flex;
        justify-content: center;
      }
  
      .projects {
        display: flex;
        justify-content: center;
      }
  
      .professional {
        display: flex;
        justify-content: center;
      }
      
  }
  
  @media (min-width: 1199px) and (max-width: 1440px) {
      
  }
  