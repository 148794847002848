.middleimg {
    border: none !important;
}

.img-fluid2 {
    height: auto;
    width: 95%;
}

.img-fluid2:hover {
    cursor: pointer;
    transform:  scale(1.1);
    z-index: 1;
}

.social-content-p {
    font-size: 18px;
    font-weight: 600;
    color: #666F74;
    text-align: left;
    align-items: justify;
}