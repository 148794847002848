.content-center {
    text-align:center;
    color:black;
    /* margin-top:20%; */
  }
  /* .error {
    font-size:100px;
    margin-bottom:1px;
  } */
  .content-center h6 {
    font-weight:bold;
  }
  .content-center button {
    border:1px solid white;
    padding:10px;
    text-align:center;
    font-family: 'Montserrat', sans-serif;
    background: #FF512F;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F09819, #FF512F);  
    /* Chrome 10-25, Safari   5.1-6 */
    background: linear-gradient(to right, #F09819, #FF512F);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color:white;
    font-weight: bold;
    cursor:pointer;
    margin-top:20px;
  }

  .container {
    position: relative;
    top: 0px;
  }

  .error-page-404 {
    font-size: 100px;
  }